import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { ApiService } from './api.service';

@Injectable()
export class ApplicationStateService {

  public blurMap = new BehaviorSubject<boolean>(true);
  private blurMapInternal: boolean = false;

  public menuOpen = new BehaviorSubject<boolean>(false);
  private menuOpenInternal: boolean = false;

  public windowWidth;
  public windowHeight;
  
  public mobileView = false;

  public debugMode = false;

  public currentDisplay = new BehaviorSubject<string>('landing-page');
  public rightOverlayFullscreen = new BehaviorSubject<boolean>(false);
  public rightOverlayFullscreen_ = false;

  public leftOverlayFullscreen = new BehaviorSubject<boolean>(true);
  public leftOverlayFullscreen_ = true;
  public currentDisplay_ = "landing-page";
  public lastDisplay_ = "landing-page";
  public lastDisplay = new BehaviorSubject<string>('landing-page');

  public setLastDisplay(): void {
    this.lastDisplay_ = this.currentDisplay_;
    this.lastDisplay.next(this.lastDisplay_);
  }

  public setCurrentDisplay(value): void {
    return;
    console.log('setCurrentDisplay: ', value, this.currentDisplay_)
    // if ( value == 'menu' ) {
    //   if ( this.currentDisplay_ == 'map' ) {
    //       this.lastDisplay_ = this.currentDisplay_;
    //       value = 'search';
    //   }
    //   else
    //   {
    //     return;
    //   }
    // }

    if ( value == 'close-main-overlay' ) {
      if( this.currentDisplay_ == 'person' ) {
        value = 'person';
        this.setRightOverlayFullscreen(true);
      }
      else if ( this.currentDisplay_ == 'search')
      {
        value = 'map';
      }
      else if ( this.currentDisplay_ == 'page') {
        value = 'page';
        //this.setRightOverlayFullscreen(true);
      }
      else {
        value = 'search';
        //this.apiService.setPage('');
      }
    }
    if ( value == 'close_right_overlay' ) {
      if ( this.lastDisplay_ == 'landing-page' ) {
        value = 'landing-page';
      }
      else if ( this.lastDisplay_ == 'person')
      {
        value = 'person';
      }
      else
      {
        this.setRightOverlayFullscreen(false);
        value = 'search';
      }
    }

    this.setLastDisplay();
    console.log(value)

    if ( value == 'close_blur' ) {
      value = 'search';
      if ( this.mobileView ) {
        value = 'map';
      }
    }
    this.currentDisplay_ = value;
    if ( this.mobileView ) {
        this.setBlurMap(value!='map');
    }
    else
    {
      this.setBlurMap(value!='search' && value!='map');
    }
    if(this.mobileView) {
      if(this.currentDisplay_ == 'person'){
        this.rightOverlayFullscreen_ = true;
        this.rightOverlayFullscreen.next(true);
      }
      else
      {
        this.rightOverlayFullscreen_ = false;
        this.rightOverlayFullscreen.next(false);
      }
    }
    console.log("display: ", this.lastDisplay_, this.currentDisplay_)
    this.leftOverlayFullscreen_ = value=='advanced-search' || value=='landing-page' || value == 'page';
    this.leftOverlayFullscreen.next(this.leftOverlayFullscreen_);
    this.currentDisplay.next(this.currentDisplay_);
  }

  public toggleMenuCloseOnMobile(): void {
    if(this.mobileView) {
      this.menuOpenInternal = !this.menuOpenInternal;
      this.menuOpen.next(this.menuOpenInternal);
    }
  }

  public setWindowSize(): void {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.mobileView = this.windowWidth < 800;
  }

  constructor(private apiService: ApiService) {
    this.setWindowSize()
  }


  public setRightOverlayFullscreen(value): void {
    this.rightOverlayFullscreen_ = value;
    this.rightOverlayFullscreen.next(this.rightOverlayFullscreen_);
  }

  public setMenuOpen(value): void {
    this.menuOpenInternal = value;
    if (this.currentDisplay_ == 'map') {
      this.setCurrentDisplay('search');
    }
    this.menuOpen.next(this.menuOpenInternal);
  }

  public getBlurMap(): Observable<boolean> {
    return this.blurMap.asObservable();
  }

  public setBlurMap(value): void {
    this.blurMapInternal = value;
    this.blurMap.next(this.blurMapInternal);
  }
}

