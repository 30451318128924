import { Component, Input } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-map-wrapper',
  templateUrl: './map-wrapper.component.html',
  styleUrl: './map-wrapper.component.less'
})
export class MapWrapperComponent {
  @Input() mainMap = true;
  @Input() width = '100%';
  @Input() height = '100%';
  @Input() events: any;

  constructor(public apiService: ApiService) {

  }

}
