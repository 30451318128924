import { Component, Input  } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-person-list-item',
  templateUrl: './person-list-item.component.html',
  styleUrl: './person-list-item.component.less'
})
export class PersonListItemComponent {

  constructor(
    public apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  @Input() person:any;
  @Input() mapDate:any;
  @Input() searchedAtCoordinates:any;

  places:any = [];

  isObject(data:any): boolean {
    return (typeof data === 'object') && (data !== null) && (data !== undefined);
  }

  public getValueToDisplay(data): string {
    if ( data !== undefined && data !== null ) {
      if ( data.hasOwnProperty(this.apiService.languageCode_) == true ) {
        return data[this.apiService.languageCode_];
      }
      if ( data.hasOwnProperty("default") == true ) {
        return data.default;
      }
    }
    return "";
  }

  public getPlaces(data:any, searchedAtCoordinates:any, mapDate:any) {
    // console.log('Residence value', data, searchedAtCoordinates, mapDate);
    let result = {
      residence: [],
      emigration: [],
      deportation: [],
    };
    if ( data !== undefined && data !== null ) {
      if ( data.hasOwnProperty('timeline_events') == true ) {
        for ( let timelineEvent of data.timeline_events ) {
          if ( !timelineEvent.hasOwnProperty('place_name_list') ) { continue; }
          switch ( timelineEvent.type_char ) {
            case 'RESIDENCE': {
                let searchedAt = false;
                let order = timelineEvent.default_on_map ? 2 : 0;
                if ( timelineEvent?.coordinates?.lat !== null && searchedAtCoordinates?.event_type === "residence" ) {
                  if ( Math.abs(timelineEvent?.coordinates?.lat - searchedAtCoordinates.lat) + Math.abs(timelineEvent?.coordinates?.lon - searchedAtCoordinates.lon) < 0.001 ) {
                    searchedAt = true;
                    order += 1;
                  }
                }
                result.residence.push({
                  default: timelineEvent.default_on_map,
                  label: timelineEvent.place_name_list[this.apiService.languageCode_],
                  searchedAt,
                  order
                });
                break;
              } 
            case 'EMIGRATION': {
              let searchedAt = false;
              let order = timelineEvent.default_on_map ? 2 : 0;
              if ( timelineEvent?.coordinates?.lat !== null && searchedAtCoordinates?.event_type === "emigration" ) {
                if ( Math.abs(timelineEvent?.coordinates?.lat - searchedAtCoordinates.lat) + Math.abs(timelineEvent?.coordinates?.lon - searchedAtCoordinates.lon) < 0.001 ) {
                  searchedAt = true;
                  order += 1;
                }
              }
              result.emigration.push({
                default: timelineEvent.default_on_map,
                label: timelineEvent.place_name_list[this.apiService.languageCode_],
                searchedAt,
                order
              });
                break;
              } 
            case 'DEPORTATION': {
              let searchedAt = false;
              let order = timelineEvent.default_on_map ? 2 : 0;
              if ( timelineEvent?.coordinates?.lat !== null && searchedAtCoordinates?.event_type === "deportation" ) {
                if ( Math.abs(timelineEvent?.coordinates?.lat - searchedAtCoordinates.lat) + Math.abs(timelineEvent?.coordinates?.lon - searchedAtCoordinates.lon) < 0.001 ) {
                  searchedAt = true;
                  order += 1;
                }
              }
              result.deportation.push({
                default: timelineEvent.default_on_map,
                label: timelineEvent.place_name_list[this.apiService.languageCode_],
                searchedAt,
                order
              });
              break;
            } 
          }
        }
      }
    }
    // result.residence.sort((a,b) => b.order - a.order);
    // result.emigration.sort((a,b) => b.order - a.order);
    // result.deportation.sort((a,b) => b.order - a.order);
    result = {
      residence: result.residence.filter(res => res.order > 0),
      emigration: result.emigration.filter(res => res.order > 0),
      deportation: result.deportation.filter(res => res.order > 0),
    }

    return result;
  }

  public getMapValueToDisplay(data): string {
    console.log('Residence value', data)
    if ( data !== undefined && data !== null ) {
      if ( data.hasOwnProperty('timeline_events') == true ) {
        let residence = undefined;
        for ( let timelineEvent of data.timeline_events ) {
          if ( timelineEvent.type_char == 'RESIDENCE' ) {
            if ( timelineEvent.default_on_map ) {
              residence = timelineEvent.place_name_list;
              break;
            }
            if ( residence == undefined ) {
              residence = timelineEvent.place_name_list;
            }
          }
        }
        if ( residence ) {
          return residence[this.apiService.languageCode_];
        } 
      }
    }
    return " - ";
  }

}
