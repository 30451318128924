<ng-template #adminEdit let-app="app" let-model="model" let-id="id" let-class="class">
  <a class="button admin-edit-button" *ngIf="apiService.adminMode" [href]="apiService.getModelAdminUrl(app, model, id)" target="_blank" [ngClass]="class" [title]="'labels.edit'| translate">
    <img class="button-icon admin-edit-icon" src="/assets/edit.svg">
  </a>
</ng-template>

<ng-template #biographicalDetail let-item="item" let-extraClass="extraClass">
  <div class="person-item person-details" *ngIf="item !== undefined" [ngClass]="extraClass">
    <ng-container *ngIf="item?.hasOwnProperty('additionalInformationText'); else noFurtherInformation">
      <div class="person-item-row has-additional-information" (click)="toggleAdditionalInformation(item.ref)">
        <span *ngIf="item.label!=null" class="person-detail-name-highlight" >{{ item.label }}:</span>
        <span>
          {{ item.value }}
        </span>
        <img src="/assets/info.svg" class="further-info-icon">
      </div>
      <div class="biographic-details-additional-information" [ngClass]="{hidden: addittionalInformationShown[item.ref]!==true}">
        <!-- <svg fill="#000000" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 100" xml:space="preserve">
          <polygon class="triangle" stroke-linejoin="round" points="60,80 150,20 240,80"></polygon>
        </svg> -->
        <div class="arrow">↳</div>
        <div class="content" [innerHTML]="item?.additionalInformationText"></div>
      </div>
    </ng-container>
    <ng-template #noFurtherInformation>
      <div class="person-item-row">
        <span *ngIf="item?.label !== null" class="person-detail-name-highlight" >{{ item.label }}:</span>
        <span>
          {{ item?.value }}
        </span>
      </div>
    </ng-template>
      
  </div>
</ng-template>


<ng-template #placeAddress let-event_type_char="event_type_char" let-map_timeline_place="map_timeline_place" let-includeCurrentPlace="includeCurrentPlace" let-place_label="place_label">
  <div class="person-event-historical-address" *ngIf="map_timeline_place?.address !== null">
    <h4>{{ place_label }}:<ng-container [ngTemplateOutlet]="adminEdit" [ngTemplateOutletContext]="{ app: 'places', model: 'place', id: map_timeline_place.id, class: 'small' }"></ng-container></h4>
    <div class="address-wrapper indent">
      <span class="address-part address-name with-line-break" *ngIf="map_timeline_place.address.name.length > 0" [tooltip]="'labels.address.name' | translate">{{ map_timeline_place.address.name }}</span>
      <span class="address-part address-institution with-line-break" *ngIf="map_timeline_place.address.institution.length > 0" [tooltip]="'labels.address.institution' | translate">{{ map_timeline_place.address.institution }}</span>
      <span class="address-part address-street" *ngIf="map_timeline_place.address.street.length > 0"  [tooltip]="'labels.address.street' | translate">{{ map_timeline_place.address.street }}<span class="address-street_number" *ngIf="map_timeline_place.address.street_number.length > 0"> {{ map_timeline_place.address.street_number }}</span></span>
      <span class="address-part address-apartment" *ngIf="map_timeline_place.address.apartment.length > 0" [tooltip]="'labels.address.apartment' | translate">{{ map_timeline_place.address.apartment }}</span>
      <div class="address-governmental-entities-wrapper">
        <span class="address-part address-community" *ngIf="map_timeline_place.address.community !== null" [tooltip]="'labels.address.community' | translate">{{ map_timeline_place.address.community }}</span>
        <span class="address-part address-district" *ngIf="map_timeline_place.address.district !== null" [tooltip]="'labels.address.district' | translate">{{ map_timeline_place.address.district }}</span>
        <span class="address-part address-state" *ngIf="map_timeline_place.address.state !== null" [tooltip]="'labels.address.state' | translate">{{ map_timeline_place.address.state }}</span>
        <span class="address-part address-country" *ngIf="map_timeline_place.address.country !== null" [tooltip]="'labels.address.country' | translate">{{ map_timeline_place.address.country }}</span>
      </div>
    </div>
    <div class="place-type-wrapper" *ngIf="map_timeline_place.place_type !== null || (map_timeline_place.specific_place_type !== null && map_timeline_place.specific_place_type.length > 0)">
      <span class="place-type person-event-specific-place-type" *ngIf="map_timeline_place.specific_place_type !== null && map_timeline_place.specific_place_type.length > 0" [tooltip]="'labels.placeType' | translate">
        <img src="/assets/info.svg" class="further-info-icon"> {{ map_timeline_place.specific_place_type }}
      </span>
      <div class="place-type person-event-place-type" *ngIf="map_timeline_place.place_type !== null && map_timeline_place.place_type.name.length > 0" [tooltip]="'labels.placeType' | translate" (click)="map_timeline_place.place_type.hidden!=map_timeline_place.place_type.hidden">
        <span><img src="/assets/info.svg" class="further-info-icon"> {{ map_timeline_place.place_type.name }}</span>
        <div class="place-type-content" [innerHTML]="map_timeline_place.place_type.short_description"></div>
      </div>
    </div>
      <!-- <span class="person-event-historical-address-place-type" *ngIf="map_timeline_place.place_type !== null || (map_timeline_place.specific_place_type !== null && map_timeline_place.specific_place_type.length > 0)">
        <h4>{{ 'labels.bio.placeType' | translate }}</h4>
      </span> -->
  </div>
  <div class="person-event-current-address" *ngIf="includeCurrentPlace && map_timeline_place?.address_current !== null">
    <h4>{{ 'labels.bio.currentPlaceName' | translate }}</h4>
    <div class="address-wrapper indent">
      <span class="address-part address-name with-line-break" *ngIf="map_timeline_place.address_current.name.length > 0" [tooltip]="'labels.address.name' | translate">{{ map_timeline_place.address_current.name }}</span>
      <span class="address-part address-institution with-line-break" *ngIf="map_timeline_place.address_current.institution.length > 0" [tooltip]="'labels.address.institution' | translate">{{ map_timeline_place.address_current.institution }}</span>
      <span class="address-part address-street" *ngIf="map_timeline_place.address_current?.street_no_longer_exists !== true && map_timeline_place.address_current.street.length > 0" [tooltip]="'labels.address.street' | translate">{{ map_timeline_place.address_current.street }}<span class="address-street_number" *ngIf="map_timeline_place.address_current.street_number.length > 0"> {{ map_timeline_place.address_current.street_number }}</span></span>
      <span class="address-part address-street" *ngIf="map_timeline_place.address_current?.street_no_longer_exists === true"  [tooltip]="'labels.address.streetNoLongerExistsHelp' | translate">{{ 'labels.address.streetNoLongerExists' | translate }}</span>
      <span class="address-part address-apartment" *ngIf="map_timeline_place.address_current.apartment.length > 0" [tooltip]="'labels.address.apartment' | translate">{{ map_timeline_place.address_current.apartment }}</span>
      <div class="address-governmental-entities-wrapper">
        <span class="address-part address-community" *ngIf="map_timeline_place.address_current.community !== null" [tooltip]="'labels.address.community' | translate">{{ map_timeline_place.address_current.community }}</span>
        <span class="address-part address-district" *ngIf="map_timeline_place.address_current.district !== null" [tooltip]="'labels.address.district' | translate">{{ map_timeline_place.address_current.district }}</span>
        <span class="address-part address-state" *ngIf="map_timeline_place.address_current.state !== null" [tooltip]="'labels.address.state' | translate">{{ map_timeline_place.address_current.state }}</span>
        <span class="address-part address-country" *ngIf="map_timeline_place.address_current.country !== null" [tooltip]="'labels.address.country' | translate">{{ map_timeline_place.address_current.country }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #timelineEvent let-event="event" let-class="class">
  <div class="person-event-wrapper bio-event" [ngClass]="['bio-event-nr-' + event.number, class]" [highlight]="['bio-event-nr-' + event.number]">
    <div class="map-marker place" [ngClass]="[event.type_char.toLowerCase(), event.map_timeline_place?.coordinates === null ? 'missing-coordinates' : '']" [tooltip]="event.map_timeline_place?.coordinates == null ? ('labels.bio.missingCoordinates' | translate ) : (class == 'date-unknown' ? ('labels.bio.missingDate' | translate ) : null) "><span>{{ event.number }}</span></div>
    <div class="timeline-tick" *ngIf="event.map_timeline_fuzzy_date.length > 0"></div>
    <div class="rows">
      <span class="person-event-detail-header">{{ event.title }}</span>
      <div class="person-event-fuzzy-date">
        <span *ngIf="event.map_timeline_fuzzy_date.length == 0">
          {{ 'labels.bio.dateUnknown' | translate }}
        </span>
        <span *ngIf="event.map_timeline_fuzzy_date.length > 0">
          {{ event.map_timeline_fuzzy_date }}
        </span>
      </div>
    </div>
    <div class="place-wrapper">
      <ng-container [ngTemplateOutlet]="placeAddress" [ngTemplateOutletContext]="{ map_timeline_place: event.map_timeline_place, includeCurrentPlace: true, place_label: event.place_label, event_type_char: event.type_char.toLowerCase() }"></ng-container>
      
      <div class="person-event-movement-address" *ngIf="event.map_timeline_movement_place_from !== null && event.map_timeline_movement_place_from_data_basis == 1">
        <ng-container [ngTemplateOutlet]="placeAddress" [ngTemplateOutletContext]="{ map_timeline_place: event.map_timeline_movement_place_from, includeCurrentPlace: true, place_label: event.place_from_label, event_type_char: event.type_char.toLowerCase() }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<div *galleryImageDef="let item">
  <pinch-zoom>
    <img [src]="item.src">
  </pinch-zoom>
  <div class="person-document-lightbox-description">
    <img src="assets/cooperation_partner_logos/arolsen_archives.png" *ngIf="getDocumentDetails(item)?.cooperationPartnerId == 2" class="source-logo">
    <img src="assets/cooperation_partner_logos/PHM_Wortmarke-Kollab_schwarz.svg" *ngIf="getDocumentDetails(item)?.cooperationPartnerId == 1" class="source-logo">
     {{ 'labels.linkToSource' | translate }}
     <a [href]="getDocumentDetails(item)?.link" target="_blank">{{getDocumentDetails(item)?.label}}</a>
  </div>
    <!-- <ngx-imageviewer [src]="data.src"></ngx-imageviewer> -->
</div>


<div class="person-wrapper mtl-scroll-shadows" (scroll)="[scrollHandler($event), true]">
  <div class="loading-spinner-wrapper" *ngIf="dataLoadingStatus==0">
    <div class="loading-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="person-content-wrapper" [ngClass]="{fullwidth: currentDisplay=='person_fullscreen', hidden: dataLoadingStatus !== 1}">
    <div class="person-header-wrapper" [ngClass]="{display: minimizeName, fullwidth: currentDisplay=='person_fullscreen'}">
      <div class="fullname">
        {{ person.fullname }}
        <ng-container [ngTemplateOutlet]="adminEdit" [ngTemplateOutletContext]="{ app: 'persons', model: 'person', id: person.id }"></ng-container>
      </div>
      <div class="birth-death">
        <div class="" *ngIf="person.biographicDetails?.birth?.value">* {{ person.biographicDetails?.birth?.value || '-' }}</div>
        <div class="" *ngIf="person.biographicDetails?.death?.value">† {{ person.biographicDetails?.death?.value || '-' }}</div>
      </div>
    </div>


    <div class="fullname-title">
      {{ person.fullname }}
      <ng-container [ngTemplateOutlet]="adminEdit" [ngTemplateOutletContext]="{ app: 'persons', model: 'person', id: person.id }"></ng-container>
    </div>


    <div class="spacer-element"></div>

    <div class="person-bio-details-wrapper">
      <ng-container [ngTemplateOutlet]="biographicalDetail" [ngTemplateOutletContext]="{ item: person.biographicDetails?.birth, extraClass: '' }"></ng-container>
      <ng-container [ngTemplateOutlet]="biographicalDetail" [ngTemplateOutletContext]="{ item: person.biographicDetails?.death, extraClass: '' }"></ng-container>
    </div>
    <div class="person-bio-details-wrapper">
      <ng-container [ngTemplateOutlet]="biographicalDetail" [ngTemplateOutletContext]="{ item: person.biographicDetails?.gender, extraClass: '' }"></ng-container>
    </div>
    <div class="person-bio-details-wrapper">
      <ng-container [ngTemplateOutlet]="biographicalDetail" [ngTemplateOutletContext]="{ item: person.biographicDetails?.education, extraClass: '' }"></ng-container>
      <ng-container [ngTemplateOutlet]="biographicalDetail" [ngTemplateOutletContext]="{ item: person.biographicDetails?.profession, extraClass: '' }"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="biographicalDetail" [ngTemplateOutletContext]="{ item: person.biographicDetails?.race, extraClass: '' }"></ng-container>
    <ng-container [ngTemplateOutlet]="biographicalDetail" [ngTemplateOutletContext]="{ item: person.biographicDetails?.basisOfPersecution, extraClass: '' }"></ng-container>
    <ng-container [ngTemplateOutlet]="biographicalDetail" [ngTemplateOutletContext]="{ item: person.biographicDetails?.shoaStatus, extraClass: '' }"></ng-container>

    <div class="person-biography-wrapper" *ngFor="let biographyText of person.proseBiographies">
      <div class="person-biography-text" [innerHTML]="biographyText.text">
      </div>
      <div class="person-biography-continue" *ngIf="biographyText?.continueUrl">
          <a href="{{ biographyText?.continueUrl }}" target="_blank">{{ uiTranslations?.fulltextAvailableOnSourcesWebsite }} <img src="/assets/external_link.svg" class="external-link-icon"></a>
      </div>
      <span class="person-biography-source" *ngIf="biographyText.source">{{ uiTranslations?.source | titlecase }}: (<a (click)="scrollToSource(biographyText.source)">{{ biographyText.source }}</a>)</span>
    </div>


    <div class="person-alternate-details-wrapper person-item-fw" *ngIf="person?.alternatePersonalDetails?.length > 0">
      <span class="person-detail-header">{{ 'labels.bio.alternatePersonalDetails' | translate }}</span>
      <div class="person-alternate-detail-wrapper" *ngFor="let alternateDetail of person.alternatePersonalDetails">
        <div class="person-item-row" class="{{ alternateDetail?.cssClass }}">
          <span class="person-detail-name-highlight">{{ alternateDetail.title }}:</span>
          <span class="person-detail-name-value">{{ alternateDetail.values }}</span>
        </div>
      </div>
    </div>
    
    <div class="person-events-map-wrapper">

      <div class="person-events-wrapper" *ngIf="(person?.events?.length + getObjectKeyLength(person.eventsDateUnknown)) > 0">
        <div class="person-events-scroll-container mtl-scrollbar">
          <div class="events-title">{{ 'labels.timeline' | translate }}</div>
          <div class="person-events-wrapper-timeline birth" *ngIf="person.eventsPersonalDetails.hasOwnProperty('BIRTH')">
            <div class="vertical-timeline birth"></div>
            <ng-container [ngTemplateOutlet]="timelineEvent" [ngTemplateOutletContext]="{event: person.eventsPersonalDetails.BIRTH, class: 'birth'}"></ng-container>
          </div>
          <div class="person-events-wrapper-timeline date-unknown" *ngIf="person.eventsDateUnknown.hasOwnProperty('RESIDENCE') || person.eventsDateUnknown.hasOwnProperty('EMIGRATION')">
            <ng-container *ngFor="let key of getObjectKeys(person.eventsDateUnknown, ['RESIDENCE', 'EMIGRATION'])">
              <ng-container *ngFor="let event of person.eventsDateUnknown[key]" [ngTemplateOutlet]="timelineEvent" [ngTemplateOutletContext]="{event: event, class: 'date-unknown'}"></ng-container>
            </ng-container>
          </div>
          <div class="person-events-wrapper-timeline">
            <div class="vertical-timeline"></div>
            <ng-container *ngFor="let event of person.events" [ngTemplateOutlet]="timelineEvent" [ngTemplateOutletContext]="{event: event, class: '', timelineTick: true}"></ng-container>
          </div>
          <div class="person-events-wrapper-timeline date-unknown" *ngIf="person.eventsDateUnknown.hasOwnProperty('IMPRISONMENT') || person.eventsDateUnknown.hasOwnProperty('DEPORTATION') || person.eventsDateUnknown.hasOwnProperty('EXPULSION')">
            <ng-container *ngFor="let key of getObjectKeys(person.eventsDateUnknown, ['EXPULSION', 'IMPRISONMENT', 'DEPORTATION'])">
              <ng-container *ngFor="let event of person.eventsDateUnknown[key]" [ngTemplateOutlet]="timelineEvent" [ngTemplateOutletContext]="{event: event, class: ''}"></ng-container>
            </ng-container>
          </div>

          <div class="person-events-wrapper-timeline death" *ngIf="person.eventsPersonalDetails.hasOwnProperty('DEATH')">
            <div class="vertical-timeline death"></div>
            <ng-container [ngTemplateOutlet]="timelineEvent" [ngTemplateOutletContext]="{event: person.eventsPersonalDetails.DEATH, class: 'death'}"></ng-container>
          </div>
        </div>
      </div>
      <app-map-wrapper [mainMap]="false" [events]="getJoinedEvents(person)" *ngIf="dataLoadingStatus === 1"></app-map-wrapper>
    </div>

    <div class="person-family-wrapper person-item-fw" *ngIf="person.family !== undefined">
      <div class="title">{{ 'labels.family' | translate }}<ng-container [ngTemplateOutlet]="adminEdit" [ngTemplateOutletContext]="{ app: 'persons', model: 'family', id: person.family.id, class: 'small' }"></ng-container></div>
      <div class="members-wrapper">
        <div class="member" *ngFor="let familyMember of person.family.members" [routerLink]="['/bio/', familyMember?.reference ]" [queryParamsHandling]="'preserve'">
          <div class="name">{{ familyMember.fullname }}<ng-container [ngTemplateOutlet]="adminEdit" [ngTemplateOutletContext]="{ app: 'persons', model: 'person', id: familyMember.id, class: 'small' }"></ng-container></div>
          <div class="birth-death">
            <div class="">* {{ familyMember?.birth || '-' }}</div>
            <div class="">† {{ familyMember?.death || '-' }}</div>
          </div>
          <div class="residence-wrapper" *ngIf="familyMember.residence !== null">
            <div class="map-marker place residence"></div>
            <div class="place-name">{{ familyMember?.residence }}</div>
          </div>
        </div>
      </div>


    </div>

    <div class="person-linkedExternalContent-wrapper person-item-fw" *ngIf="person?.linkedExternalContent?.length > 0">
      <span class="person-detail-header">{{ 'labels.bio.linkedExternalContent' | translate }}</span>
      <p [innerHTML]="'labels.matchingExplanation' | translate" class="matching-footnote"></p>
      <!-- <p [innerHtml]="'labels.externalContentTeaser' | translate"></p> -->
      <div class="person-cooperation-partners-container" *ngFor="let externalContent of person.linkedExternalContent">
        <div class="person-cooperation-partner-wrapper">
          <div class="person-cooperation-partner-content mtl-scrollbar">
            <div class="cooperation-partner-introduction-wrapper">
              <ng-container *ngIf="externalContent.cooperation_partner !== undefined">

                <div class="cooperation-partner-logo" *ngIf="externalContent.cooperation_partner !== undefined">
                  <a href="https://arolsen-archives.org/" target="_blank" *ngIf="externalContent.cooperation_partner_id == 2">
                    <img src="assets/cooperation_partner_logos/arolsen_archives.png">
                    <span>https://arolsen-archives.org/</span>
                  </a>
                  <a href="https://gedenkbuch.muenchen.de/" target="_blank" *ngIf="externalContent.cooperation_partner_id == 1">
                    <img src="assets/cooperation_partner_logos/PHM_Wortmarke-Kollab_schwarz.svg">
                    <span>https://gedenkbuch.muenchen.de/</span>
                  </a>

                </div>
                <div [innerHtml]="externalContent.cooperation_partner.description" class="cooperation-partner-description">
                </div>
              </ng-container>
            </div>
            <div class="person-externalContent-reference-wrapper" *ngFor="let externalContentReference of externalContent.references">
              <div class="external-content-matching-wrapper">
                <div class="data-points names" [tooltip]="'labels.dataFromSource' | translate">
                  <span>{{ 'labels.forenameS' | translate }}:</span><span>{{ externalContentReference.matching_data.forename || ' - ' }}</span>
                  <span>{{ 'labels.surnameS' | translate }}:</span><span>{{ externalContentReference.matching_data.surname || ' - ' }}</span>
                </div>
                <div class="data-points dates" [tooltip]="'labels.dataFromSource' | translate">
                  <span *ngIf="externalContentReference.matching_data.birth_date || externalContentReference.matching_data.birth_place">* {{ externalContentReference.matching_data.birth_date ? (externalContentReference.matching_data.birth_date.split('-').reverse().join('.') + ' ') : '' }}{{ externalContentReference.matching_data.birth_place ? ('labels.in' | translate) : '' }} {{ externalContentReference.matching_data.birth_place || '' }}</span>
                  <div *ngIf="!(externalContentReference.matching_data.birth_date || externalContentReference.matching_data.birth_place)"><br></div>
                  <span *ngIf="externalContentReference.matching_data.death_date || externalContentReference.matching_data.death_place">† {{ externalContentReference.matching_data.death_date ? (externalContentReference.matching_data.death_date.split('-').reverse().join('.') + ' ') : '' }}{{ externalContentReference.matching_data.death_place ? ('labels.in' | translate) : '' }} {{ externalContentReference.matching_data.death_place || '' }}</span>
                  <div *ngIf="!(externalContentReference.matching_data.death_date || externalContentReference.matching_data.death_place)"><br></div>
                </div>
                <div class="score-link">
                  <span class="automatic-matching-score" *ngIf="!externalContentReference.manually_confirmed_match" [tooltip]="'labels.cooperationPartnerScore' | translate">{{ 'labels.score' | translate }}: {{ externalContentReference.score.toFixed(2) }}</span>
                  <span class="automatic-matching-score" *ngIf="externalContentReference.manually_confirmed_match" [tooltip]="'labels.manuallyConfirmed' | translate">{{ 'labels.confirmed' | translate }} ({{ externalContentReference.score.toFixed(2) }})</span>
                  <a href="{{ externalContentReference?.link }}" target="_blank" class="link-to-source" [tooltip]="'labels.linkToSource' | translate">{{ externalContentReference.reference }}
                    <img src="/assets/external_link.svg" class="external-link-icon">
                  </a>
                </div>
              </div>

              <div class="reference-document gallery-image" *ngFor="let document of externalContentReference.external_data.thumbnails; let i = index">
                <img [src]="setDocumentDetails(document, externalContentReference?.link, externalContent.cooperation_partner_id)">
                <span class="label">{{ document.label }}</span>
              </div>

              <div class="reference-documents-wrapper" [gallerize]="getGalleryReference(externalContent.internal_name, externalContentReference.reference)">
                <!-- <img [src]="document.img" *ngFor="let document of externalContentReference.external_data.documents"> -->
                
                <div class="reference-document gallery-image" *ngFor="let document of externalContentReference.external_data.documents; let i = index">
                  <img [src]="setDocumentDetails(document, externalContentReference?.link, externalContent.cooperation_partner_id)">
                  <span class="label">{{ document.label }}</span>
                </div>
              </div>
              <div class="information-wrapper">
                <div class="prose-text" *ngIf="externalContentReference.external_data.text !== undefined">
                  <h3>{{ 'labels.biography' | translate }}</h3>
                  <span [innerHtml]="externalContentReference.external_data.text"></span><a href="{{ externalContentReference?.link }}" target="_blank" class="link-to-source" [tooltip]="'labels.linkToSource' | translate">{{ 'labels.continueReading' | translate }}
                  <img src="/assets/external_link.svg" class="external-link-icon">
                </a></div>
                <div class="grid archive" *ngIf="externalContentReference.external_data.archive !== undefined"><span class="label">{{ 'labels.archiveUnit' | translate }}:</span><span>{{ externalContentReference.external_data.archive.number }} {{ externalContentReference.external_data.archive.name }}</span></div>
                <div class="grid prisoner_number" *ngIf="externalContentReference.external_data.prisoner_number !== undefined"><span class="label">{{ 'labels.prisonerNumber' | translate }}:</span><span>{{ externalContentReference.external_data.prisoner_number }}</span></div>
                <div class="grid position" *ngIf="externalContentReference.external_data.pos !== undefined"><span class="label">{{ 'labels.position' | translate }}:</span><span>{{ externalContentReference.external_data.pos }}</span></div>
              </div>
              <ng-container [ngTemplateOutlet]="adminEdit" [ngTemplateOutletContext]="{ app: 'cooperationpartners', model: 'cooperationpartnerautomaticmatchingroutineitem', id: externalContentReference.id, class: 'small' }"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="person-annotations-wrapper person-item-fw" *ngIf="person?.annotations?.length > 0">
      <span class="person-detail-header">{{ 'labels.bio.annotations' | translate }}</span>
      <div class="person-annotation-wrapper" *ngFor="let annotation of person.annotations">
        <div class="person-annotation-row" class="{{ annotation?.cssClass }}">
          <span class="person-detail-name-highlight">{{ annotation.name }}<ng-element *ngIf="annotation.sourceNumber.length > 0"> (<a (click)="scrollToSource(annotation?.sourceNumber)">{{ annotation?.sourceNumber }}</a>) </ng-element></span>
            {{ annotation.annotation }}
        </div>
      </div>
    </div>

    <div class="person-externalReferences-wrapper person-item-fw" *ngIf="person?.externalReferences?.length > 0">
      <span class="person-detail-header">{{ 'labels.bio.externalReferences' | translate }}</span>
      <div class="person-externalReference-wrapper" *ngFor="let externalReference of person.externalReferences">
        <div class="person-externalReference-row" class="{{ externalReference.cssClass }}">
          <span class="person-detail-name-highlight">{{ externalReference.name }}<ng-element *ngIf="externalReference?.sourceNumber"> (<a (click)="scrollToSource(externalReference?.sourceNumber)">{{ externalReference?.sourceNumber }}</a>)</ng-element>:</span>
          <ng-element *ngIf="externalReference?.link !== null">
            <a href="{{ externalReference.link }}" target="_blank">{{ externalReference.value }}
              <img src="/assets/external_link.svg" class="external-link-icon">
            </a>
          </ng-element>
          <ng-element *ngIf="externalReference?.link === null">
            {{ externalReference.value }}
          </ng-element>
        </div>
      </div>
    </div>

    <div class="person-source-wrapper person-item-fw">
      <span class="person-detail-header">{{ 'labels.bio.sources' | translate }}</span>
      <div class="person-annotation-wrapper" *ngFor="let source of person?.sources">
        <div class="person-source-row" id="source-{{ source.number }}" class="{{ source.cssClass }}">
          <div class="person-source-number">{{ source.number }}</div>
          <div class="person-source-name">
              <div class="name">
                {{ source.name }}
                <div class="ref-wrapper" *ngIf="source.references.length > 0">
                  {{  'labels.idNo' | translate }}
                  <div class="ref" *ngFor="let ref of source.references; let i = index">
                    <span *ngIf="i > 0">, </span>
                    <a [href]="ref.link" target="_blank" *ngIf="ref.link !== null">
                      {{ ref.value }}
                      <img src="/assets/external_link.svg" class="external-link-icon">
                    </a>
                    <span *ngIf="ref.link === null">
                      {{ ref.value }}
                    </span>
                </div>
              </div>
            </div>
            <div class="short_description" *ngIf="source?.short_description !== null">
              {{ source.short_description }}
            </div>
            <div *ngIf="source.link!='' && source.link!=null">
              <a href="{{ source.link }}" class="person-source-link" target="_blank">
                {{ source.link }}
                <img src="/assets/external_link.svg" class="external-link-icon">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="person-functions-wrapper person-item-fw">
      <div class="person-report-error-wrapper">
        <a href="mailto:error-report@tracingthepast.org?subject={{ 'labels.bio.reportErrorEmail.subject' | translate }} {{ apiService.personReference }}&body={{ 'labels.bio.reportErrorEmail.body' | translate }}">{{ 'labels.bio.reportError' | translate }}</a>
      </div>
      <div class="person-show-link-wrapper" (click)="showLink()">
        {{ uiTranslations?.showLink }}{{ 'labels.bio.showLink' | translate }}
      </div>
      <div class="person-link-wrapper" *ngIf="linkUrl!=''">
        <div class="person-link">{{ linkUrl }}</div> <span class="person-copy-link" (click)="copyLink()" [ngClass]="{hidden: copiedSucessfully}">{{ 'labels.bio.copyLink' | translate }}</span> <span class="person-coppied-sucessfully" [ngClass]="{hidden: !copiedSucessfully}">{{ 'labels.bio.copiedSuccessfully' | translate }}</span>
      </div>
    </div>
    <div class="person-redirect-to-backdrop" *ngIf="person.hasOwnProperty('redirectTo')">
      <div class="person-redirect-to-wrapper">
        <h3>{{ 'labels.entryWasMerged' | translate }}</h3>
        <span>{{ 'labels.entryWasMergedExplanation' | translate }}</span>
        <table>
          <app-person-list-item [person]="person.redirectTo" [searchedAtCoordinates]="null" [mapDate]="apiService.mapDate"></app-person-list-item>
        </table>
        <div class="link-wrapper">
          <div>{{ 'labels.url' | translate }}:</div>
          <div class="person-link">{{ getRedirectUrl() }}</div>
        </div>
      </div>
    </div>
    <div class="biography-consent-backdrop-wrapper" [ngClass]="{hidden: apiService.consentGivenBiography_}">
      <div class="biography-consent-wrapper">
        <h2>{{ 'gdprConsent.biographyConsentHeader' | translate }}</h2>
        <div class="" [innerHtml]="'gdprConsent.biographyConsentText' | translate">
        </div>
        <div class="outer-consent-wrapper">

          <div class="consent-wrapper">
            <label class="toggle-switch">
              <input id="rememberConsentBiography" type="checkbox" [checked]="rememberConsentBiography" (change)="rememberConsentBiography=!rememberConsentBiography"/>
              <span class="slider round"></span>
            </label>
            <label for="rememberConsentBiography" [innerHtml]="'gdprConsent.biographyConsentLabelText' | translate"></label>
          </div>

          <div class="consent-wrapper">
            <label class="toggle-switch">
              <input id="rememberConsentBiographyPermanent" type="checkbox" [checked]="rememberConsentBiographyPermanent" (change)="rememberConsentBiographyPermanent=!rememberConsentBiographyPermanent"/>
              <span class="slider round"></span>
            </label>
            <label for="rememberConsentBiographyPermanent">
              {{ 'gdprConsent.rememberConsentForMyNextVisit' | translate }}
            </label>
          </div>

          <button (click)="apiService.setBiographyConsentGiven(rememberConsentBiographyPermanent);" [ngClass]="{disabled: !rememberConsentBiography}">
            <span>{{ 'gdprConsent.iAgree' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="dataLoadingStatus==2" class="error-message">
    <div [innerHtml]="'errors.notFound' | translate"></div>
  </div>
  <div *ngIf="dataLoadingStatus==3" class="error-message">
    <div [innerHtml]="'errors.internal' | translate"></div>
  </div>
</div>
 