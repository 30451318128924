import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ApplicationStateService } from '../application-state.service';




@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.less']
})
export class PageComponent implements OnInit {
  @Input() page;

  public uiTranslations;
  public currentDisplay = '';
  public lastDisplay = '';

  public currentPage = '';

  public paypalDonationButton;

  public rememberConsent = false;

  public dataLoadingStatus = 0;


  constructor(private apiService: ApiService, public applicationState: ApplicationStateService) {
    // console.log(this.route.snapshot.paramMap);
    // var cooperationpartner_reference = this.route.snapshot.paramMap.get('cooperationpartner');
    // if ( cooperationpartner_reference != "" ) {
    //   this.apiService.setCooperationPartnerData(cooperationpartner_reference);
    // }
    

    this.applicationState.currentDisplay.subscribe(
        value => {
          this.currentDisplay = value;
        }
    );

    this.applicationState.lastDisplay.subscribe(
      value => {
        this.lastDisplay = value;
      }
  );
    
    this.apiService.paypalDonationButton.subscribe(
      value => {
        this.paypalDonationButton = value;
    });

    this.apiService.pageDataLoadingStatus.subscribe(
      value => {
        // console.log("page Data loading status", value)
        if(value == 2) {
          // console.log('getting here vokjhvdslkjvdkjvopsövsyhhäp');
          this.applicationState.setCurrentDisplay('landing-page');
          this.apiService.setPage('');
          value = 0;
        }
        this.dataLoadingStatus = value;
    });

    this.apiService.currentPage.subscribe(
      value => {
        this.currentPage = value;
    });

    this.apiService.permanentConsentGiven.subscribe(
      value => {
        this.rememberConsent = value;
    });

    

    this.apiService.page.subscribe(
      data => {
        if ( data.hasOwnProperty('label') == true ) {
          this.page = data;
          if(this.currentDisplay == 'landing-page' && this.currentDisplay == 'landing-page'){
            this.applicationState.setCurrentDisplay('page');
            this.applicationState.setRightOverlayFullscreen(true);
          }
        }
      }
    );
    this.apiService.uITextElements.subscribe(
      data => {
        if ( data.hasOwnProperty('page') == true ) {
          this.uiTranslations = data.page;
        }
      }
    );
    
    // var slug = this.route.snapshot.paramMap.get('slug');
    // if ( slug != "" ) {
    //   this.slug = slug;
    //   console.log(slug);
    //   this.apiService.setPage(slug);
    //   this.applicationState.setCurrentDisplay('page');
    // }
  }

  ngOnInit(): void {
  }

}
