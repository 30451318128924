import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { debounce, debounceTime, takeUntil, distinctUntilChanged } from 'rxjs/operators';

import { Loader } from "@googlemaps/js-api-loader"
import { catchError, map, take, skipWhile } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';

import MTLAppConfig from '../assets/mtl-app-config.json';
import GitVersion from '../assets/version.json';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterOutlet } from '@angular/router';

export interface LanguageChoice {
  value: string,
  display: string,
}

const paypalDonationButtonChoices = {
  'en': {
    'url': 'https://www.paypal.com/donate/?hosted_button_id=W37E7AGWJGGLC',
    'imgSrc': '/assets/PayPal_donation_en.svg',
    'imgAlt': 'Donate via PayPal'
  },
  'de': {
    'url': 'https://www.paypal.com/donate/?hosted_button_id=9VNAESYR43WZJ',
    'imgSrc': '/assets/PayPal_donation_de.svg',
    'imgAlt': 'Spenden mit PayPal'
  }
};

const languageChangeNotification = {
  'en': 'To render the map in english the page has to be reloaded manually.',
  'de': 'Um die Karte auf Deutsch darzustellen muss die Seite manuell neu geladen werden.',
}

const MAP_AGGREGATIONS = ['residence', 'emigration', 'deportation'];

const advancedSearchFields = ['res_fd_from', 'res_fd_to', 'res_quarter', 'res_district', 'res_state', 'res_country', 'birth_fd_from', 'birth_fd_to', 'birth_place', 'death_fd_from', 'death_fd_to', 'death_place', 'emigration_fd_from', 'emigration_fd_to', 'emigration_place', 'emigration_place_from', 'expulsion_fd_from', 'expulsion_fd_to', 'expulsion_place', 'expulsion_place_from', 'imprisonment_fd_from', 'imprisonment_fd_to', 'imprisonment_place', 'deportation_fd_from', 'deportation_fd_to', 'deportation_place', 'deportation_place_from'];

const GOOGLE_MAPS_API_KEY = 'AIzaSyCPhxI8OdaiHW6j0NUeH8LGoT5lugDpnS4';
@Injectable()
// BASE_URL = "https://unidosmexico85.firebaseio.com";


export class ApiService {
  public baseUrl = MTLAppConfig.server.address;
  // http options used for making API calls
  private httpOptions: any;
  // public searchData: Observable<any[]>;
  // public geoPositionData: Observable<any[]>;
  zoom = 6;
  tl_lat = 66;
  tl_lon = -180;
  br_lat = -66;
  br_lon = 180;

  lat = 50.3061856;
  lon = 12.3007083;

  mapOptions = {
    minZoom: 2,
    maxZoom: 24
  };

  private auth = {
    username: "mappingthelives.org-240317",
    password: "ZVkRUqi8u=3]/LLY5d!t}Lw2?5_!ObM(2'2{HqQ70iXN;s.$^A"
  }

  private jwtTokenRefresh: string = "";
  private jwtTokenAccess: string = "";
  private jwtTokenAuthConnectionAttempts = 0;
  public tokenExpires: any;
  public tokenExpiresDate: any;
  public refreshTokenTimeout: any;

  public isAuthenticated: boolean = false;
  public isAuthenticatedSub = new BehaviorSubject<boolean>(false);
  public adminMode: boolean = false;

  public refreshAddressSearch: boolean = false;

  initialized: boolean = false;

  public languageChoices = MTLAppConfig.languageChoices;
  public version = GitVersion.version;

  public mapAggregations:any = ['residence'];

  public mapDate = null;
  private searchUrlQueryString = '';


  public currentDisplay = 'landing-page';
  public currentSearchDisplay = 'base-search';
  public mainOverlayMinimized = false;
  public displayBiography = false;
  public displayBiographyFullscreen = false;
  public menuOpen = false;

  public isMobile = false;
  public screenWidth = null;
  public screenHeight = null;

  getModelAdminUrl(app: string, model: string, id: number):string {
    return `${MTLAppConfig.server.address}admin/${app}/${model}/${id}/change`;
  }


  setCurrentDisplay(command: string) {
    console.log("Set current display", command);
    if ( command == 'close-main-overlay' ) {
      if ( this.currentDisplay == 'search' ) {
        this.mainOverlayMinimized = true;
      } else if(this.currentDisplay == 'page') {
        this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
      }
      if ( this.displayBiography ) {
        this.displayBiographyFullscreen = true;
      }
      this.currentDisplay = 'search';
    }
    else if ( command == 'close-biography' ) {
      this.currentDisplay = 'search';
      this.displayBiographyFullscreen = false;
      this.displayBiography = false;
    }
    else if ( command == 'close-blur' ) {
      this.currentDisplay = 'search';
      this.displayBiographyFullscreen = false;
      this.displayBiography = false;
      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
    }
    else if ( command == 'person' ) {
      this.currentDisplay = 'search';
      this.displayBiography = true;
      this.displayBiographyFullscreen = false;
      if ( this.isMobile ) {
        this.mainOverlayMinimized = true;
      }
    }
    else if ( command == 'person-fullscreen' ) {
      this.currentDisplay = 'search';
      this.displayBiography = true;
      this.displayBiographyFullscreen = true;
      this.mainOverlayMinimized = true;
    }
    else if ( command == 'advanced-search' ) {
      this.mainOverlayMinimized = false;
      this.currentDisplay = 'search';
      this.displayBiographyFullscreen = false;
      this.currentSearchDisplay = 'advanced-search';
    }
    else if ( command == 'results' ) {
      this.mainOverlayMinimized = false;
      this.currentDisplay = 'search';
      this.displayBiographyFullscreen = false;
      this.currentSearchDisplay = 'results';
    }
    else if ( command == 'base-search' ) {
      this.mainOverlayMinimized = false;
      this.currentDisplay = 'search';
      this.displayBiographyFullscreen = false;
      this.currentSearchDisplay = 'base-search';
    }
    else if ( command == 'search' ) {
      if ( this.mainOverlayMinimized === true ) {
        this.mainOverlayMinimized = false;
      } else {
        if ( this.currentSearchDisplay != 'base-search' ) {
          if ( this.currentSearchDisplay == "results" ) {
            if ( this.searchUrlQueryString.length > 0 ) {
              this.currentSearchDisplay = 'advanced-search';
            } else {
              this.currentSearchDisplay = 'base-search';
            }
          } else {
            if ( this.searchByQuery.results.length > 0 || this.searchByAddress.results.length > 0 ) {
              this.currentSearchDisplay = 'results';
            }
          }
        }
      }
      if ( this.currentDisplay == 'search' ) {
      }
      this.currentDisplay = 'search';
      this.displayBiographyFullscreen = false;
    }
    else {
      this.displayBiographyFullscreen = false;
      this.displayBiography = false;
      this.mainOverlayMinimized = false;
      this.currentDisplay = command;
    }
  }

  public setMapDate(value:any) {
    this.mapDate = value;
    this.router.navigate([], {relativeTo: this.route, queryParams: {map_date: value}, queryParamsHandling: 'merge'});
    // this.triggerFetchData();
  }

  public toggleMapAggregation(type: string) {
    if ( !MAP_AGGREGATIONS.includes(type) ) { return; }
    let index = this.mapAggregations.indexOf(type);
    if ( index < 0 ) {
      this.mapAggregations.push(type);
    } else {
      this.mapAggregations.splice(index, 1);
    }
    if ( this.mapAggregations.length < 1 ) {
      this.mapAggregations = ['residence'];
    }

    this.router.navigate([], {relativeTo: this.route, queryParams: {map_agg: this.mapAggregations.join(',')}, queryParamsHandling: 'merge'});
  }


  public languageCode_ = "en";
  public languageCode = new BehaviorSubject<string>("en");
  public uITextElements = new BehaviorSubject<any>({});
  public uITextElements_: any;

  public searchParametersChanged = new BehaviorSubject<boolean>(false);
  public googleMapsApiLoaded = new BehaviorSubject<boolean>(false);
  public googleMapsApiLoaded_ = false;
  public geoPositionData = new BehaviorSubject<any>({});
  public personReference = '';

  public searchByQuery_ = <any>[];
  public searchByQuery:any = {
    results: [],
    aggregations: [],
    count: 0,
    next: null
  };
  public searchByQueryNextPage_ = null;
  public searchByQueryNextPage = new BehaviorSubject<string>(null);
  public searchByQueryResultCount_ = null;
  public searchByQueryResultCount = new BehaviorSubject<number>(0);
  public searchInProgress = new BehaviorSubject<boolean>(false);

  public searchByAddress:any = {
    results: [],
    searchedAtCoordinates: null,
    count: 0,
    next: null
  };
  // public searchByAddress = new BehaviorSubject<any>({});  
  public programmaticallySetMapFocus = new BehaviorSubject<any>({});  
  public searchByAddressNextPage_ = null;
  public searchByAddressNextPage = new BehaviorSubject<string>(null);
  public searchByAddressResultCount_ = null;
  public searchByAddressResultCount = new BehaviorSubject<number>(0);

  public supportUsText_ = "";
  public supportUsText = new BehaviorSubject<string>("");


  public cooperationpartner = "";
  public cooperationPartnerData_ = {};
  public cooperationPartnerData = new BehaviorSubject<any>({});
  public embeddedView = new BehaviorSubject<boolean>(false);
  public mapUpdates_ = true;
  public mapUpdates = new BehaviorSubject<boolean>(true);
  
  public slug = '';
  public page = new BehaviorSubject<any>({});
  public currentPage = new BehaviorSubject<string>('');
  public pageDataLoadingStatus = new BehaviorSubject<number>(0);
  public menuItems_;
  public menuItems = new BehaviorSubject<any>([]);
  public personData = new BehaviorSubject<any>({});
  public personData_:any = null;

  public lastViewedBiographies_ = [];
  public lastViewedBiographies = new BehaviorSubject<any>([]);

  public personDataLoadingStatus = new BehaviorSubject<number>(0);
  public googleMapsGDPRConsent = new BehaviorSubject<boolean>(false);
  public paypalDonationButton = new BehaviorSubject<any>(paypalDonationButtonChoices.en);
  
  public activeSearchParametersFromAdvancedSearch = new BehaviorSubject<boolean>(false);
  
  public consentGivenGoogleMaps_ = false;
  public consentGivenGoogleMaps = new BehaviorSubject<boolean>(false);

  public consentGivenBiography_ = false;
  public consentGivenBiography = new BehaviorSubject<boolean>(false);

  public permanentConsentGivenGoogleMaps_ = false;
  public permanentConsentGiven = new BehaviorSubject<boolean>(false);

  private searchUrlGetParameters = new Subject<any>();
  private currentGetParameters:any = {};

  private searchUrlCoordinatesGetParameters = new Subject<any>();
  private currentGetCoordinatesParametersString = '';
  public searchedAtCoordinates:any = {};

  public initialQueryParams = new BehaviorSubject<any>({});
  
  public currentlyLoading_ = {
    list: false,
    map: false,
    next: false,
  };
  public currentlyLoading = new BehaviorSubject<any>({});
  private currentlyDisplayedListUrl = '';

  debounceTimeMS: number = 500;

  pageSize:number = 100;

  searchParameters = {};

  updateTimer: any;
  updateInterval = 800;

  namingQualifiers = {};
  basisOfPersecutionQualifiers = {};
  dateFlags = {};
  biologicalGender = {};

  queryParams:any = {}

  googleMapsAutocomplete:any;
  mapDataLoading: boolean = false;

  person = false;
  currentRoute: string;
  public fragment = '';
  fragmentSubscription: any;

  searchQueryParamNames = ['restrict_to_map_bounds','filter_results','forename','surname','maidenname','res_fd','res_single_fd','res_fd_from','res_fd_to','res_street','res_streetnr','res_community','res_quarter','res_district','res_state','res_country','birth_fd','birth_single_fd','birth_fd_from','birth_fd_to','birth_place','death_fd','death_single_fd','death_fd_from','death_fd_to','death_place','emigration_fd','emigration_single_fd','emigration_fd_from','emigration_fd_to','emigration_place','ation_place_from','expulsion_fd','expulsion_single_fd','expulsion_fd_from','expulsion_fd_to','expulsion_place','expulsion_place_from','imprisonment_fd','imprisonment_single_fd','imprisonment_fd_from','imprisonment_fd_to','imprisonment_place','deportation_fd','deportation_single_fd','deportation_fd_from','deportation_fd_to','deportation_place','deportation_place_from','map_date','map_agg']

  public setGoogleMapsConsentGiven(permanent=false): void {
    this.consentGivenGoogleMaps_ = true;
    this.consentGivenGoogleMaps.next(true);
    if( permanent ) {
      localStorage.setItem('MTL_rememberConsent_google_maps', 'true');
      // localStorage.setItem('MTL_languageCode', this.languageCode_);
    }
    this.initGoogleMap();

  }

  public setBiographyConsentGiven(permanent=false): void {
    this.consentGivenBiography_ = true;
    this.consentGivenBiography.next(true);
    if( permanent ) {
      localStorage.setItem('MTL_rememberConsent_biography', 'true');
      // localStorage.setItem('MTL_languageCode', this.languageCode_);
    }

  }

  async getAuthenticationToken(): Promise<void> {

  }

  setMapToUserLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          this.programmaticallySetMapFocus.next({
            type: "center",
            values: {
              zoom: 17,
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            }
          });
        },
        () => {
          
        }
      );
    }
  }

  initGoogleMapsAutoCompleteField(): void {
    var checkExist = setInterval(() => {
      if(document.getElementById("google-maps-find-address-autocomplete")){
        const input = document.getElementById("google-maps-find-address-autocomplete") as HTMLInputElement;
        const options = {};

        this.googleMapsAutocomplete = new google.maps.places.Autocomplete(input, options);
        this.googleMapsAutocomplete.addListener("place_changed", () => {
          const place = this.googleMapsAutocomplete.getPlace();
          if (!place.geometry || !place.geometry.location) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            // window.alert(this.mapUITranslations?.noPlace + "'" + place.name + "' " + this.mapUITranslations?.wasFound);
          }

          // If the place has a geometry, then present it on a map.
          if  (place?.geometry?.viewport ) {
            this.programmaticallySetMapFocus.next({
              type: 'viewport',
              values: place.geometry.viewport.toJSON()
            });
          } else {
            this.programmaticallySetMapFocus.next({
              type: 'center',
              values: {
                zoom: 17,
                ...place.geometry.location
              }
            });
          }
        });
        clearInterval(checkExist);
      }
   }, 100);
  }



  async initGoogleMap(reInit=false): Promise<void> {
    if ( reInit ) {
      document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach(v => {
        v.remove();
      });
      if ( typeof google !== "undefined" && typeof google.maps !== 'undefined') {
        delete google.maps;
      }
    }
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      language: this.languageCode_, 
      region: 'DE',
      libraries: ['visualization', 'places']
    });
    loader.importLibrary('maps').then(() => {
      this.googleMapsApiLoaded_ = true;
      this.initialized = true;
      this.googleMapsApiLoaded.next(true);
      this.initGoogleMapsAutoCompleteField();
    });
    // loader.load().then(async () => {
    //   const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;

    //   this.googleMapsApiLoaded_ = true;
    //   this.initialized = true;
    //   this.googleMapsApiLoaded.next(true);
    //   this.initGoogleMapsAutoCompleteField();
    // });
  }

  public togglePermanentConsent(): void {
    this.permanentConsentGivenGoogleMaps_ = !this.permanentConsentGivenGoogleMaps_;
    if(!this.permanentConsentGivenGoogleMaps_) {
      localStorage.removeItem('MTL_rememberConsent_google_maps');
      // localStorage.removeItem('MTL_languageCode');
    }
    else if( this.consentGivenGoogleMaps_ == true ){
      localStorage.setItem('MTL_rememberConsent_google_maps', 'true');
      // localStorage.setItem('MTL_languageCode', this.languageCode_);
    }
    this.permanentConsentGiven.next(this.permanentConsentGivenGoogleMaps_);
  }

  public revokePermanentConsent(): void {
    this.permanentConsentGivenGoogleMaps_ = false;
    localStorage.removeItem('MTL_rememberConsent_google_maps');
    localStorage.removeItem('MTL_rememberConsent_biography');
    this.permanentConsentGiven.next(this.permanentConsentGivenGoogleMaps_);
  }

  // public pageIsCurrent(slug): boolean {
  //     return this.slug == slug;
  //   }

  // public personIsCurrent(reference): boolean {
  //   return this.personReference == reference;
  // }

  private fitToBounds = new BehaviorSubject<any>({});

  private pad(num:number, size:number): string {
      let s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
  }

  public getFitToBounds(): Observable<any> {
    return this.fitToBounds.asObservable();
  }

  public toggleMapUpdates(): void {
    this.mapUpdates_ = !this.mapUpdates_;
    this.mapUpdates.next(this.mapUpdates_);
  }

  public replaceNamingQualifierPlaceholder(name): string {
    for(const [qualifier, qualifierTranslation] of Object.entries(this.namingQualifiers)) {
      name = name.replace(qualifier, qualifierTranslation);
    }
    return name;
  }

  public replaceBasisOfPersecutionPlaceholder(name): string {
    for(const [qualifier, translation] of Object.entries(this.basisOfPersecutionQualifiers)) {
      name = name.replace(qualifier, translation);
    }
    return name;
  }

  public replaceDateFlagPlaceholder(name): string {
    for(const [qualifier, translation] of Object.entries(this.dateFlags)) {
      name = name.replace(qualifier, translation);
    }
    return name;
  }

  public replaceGenderPlaceholder(name): string {
    for(const [qualifier, translation] of Object.entries(this.biologicalGender)) {
      name = name.replace(qualifier, translation);
    }
    return name;
  }

  public setFitToBounds( value ): void {
    this.fitToBounds.next(value);
  }


  public getMenuItems(): void {
    var url = MTLAppConfig.server.address + 'page/?format=json&language=' + this.languageCode_;
    this.http.get(url, this.getHttpOptions()).subscribe(
      data => {
        this.menuItems_ = data;
        this.menuItems.next(this.menuItems_);
    },
    error => console.error(error),
    () => {
      }
    );
  }

  public setPage( value: string ): void {
    if (value != "" && value != null) {
      this.slug = value.split('#')[0].split('?')[0];
      var url = MTLAppConfig.server.address + 'page/' + this.slug + '/?format=json&language=' + this.languageCode_;
      this.http.get(url, this.getHttpOptions()).subscribe(
        data => {
          if (data.hasOwnProperty('message')){
            this.slug = "";
            this.page.next({});
            this.currentPage.next("");
            this.pageDataLoadingStatus.next(2)
          }
          else
          {
            this.page.next(data);
            this.currentPage.next(this.slug);
            this.pageDataLoadingStatus.next(1);
          }
      },
      error => {
        console.error(error);
        this.pageDataLoadingStatus.next(2);
      });
    }
    else
    {
      this.slug = "";
      this.page.next({});
      this.currentPage.next("");
      this.pageDataLoadingStatus.next(0);
    }
  }

  public changeLanguageCode(languageCode): void {
    if (languageCode != this.languageCode_)
    {
      this.router.navigate([], {relativeTo: this.route, queryParams: {language: languageCode}, queryParamsHandling: 'merge'});
    }
  }

  private setLanguageCode(languageCode): void {
      if (languageCode == null){
        this.languageCode_ = "en";
      }
      else
      {
          this.languageCode_ = languageCode;
      }
      this.languageCode.next(this.languageCode_);
      // if( this.consentGivenGoogleMaps_ == true ){
      //   localStorage.setItem('MTL_languageCode', this.languageCode_);
      // }
      // // if(throughUI) {
      // //   window.alert(languageChangeNotification[languageCode]);
      // // }
      // this.fetchUITextElements();
      // // if (this.currentDisplay)
      this.fetchPersonData();
      this.getMenuItems();
      this.setPage(this.slug);
      this.paypalDonationButton.next(paypalDonationButtonChoices[this.languageCode_]);
  }

  // search_parameters_by_type = {
  //   string: [
  //     {field: firstname, apiField: firstname},
  //     {field: lastname, apiField: lastname},
  //                                 'res_street', 'res_streetnr', 'res_community', 'res_quarter', 'res_district', 'res_state', 'res_country',
  //                                 'birth_place',
  //                                 'death_place',
  //                                 'emigration_place',
  //                                 'expulsion_place',
  //                                 'imprisonment_place',
  //                                 'deportation_place'
  //                                 ],
  //                             'date': [
  //                                 'res_fd_from', 'res_fd_to',
  //                                 'birth_fd_from', 'birth_fd_to',
  //                                 'death_fd_from', 'death_fd_to',
  //                                 'emigration_fd_from', 'emigration_fd_to',
  //                                 'expulsion_fd_from', 'expulsion_fd_to',
  //                                 'imprisonment_fd_from', 'imprisonment_fd_to',
  //                                 'deportation_fd_from', 'deportation_fd_to',
  //
  // }


  getDateToString(data, identifier): any {
    let year = data[`${identifier}_year`] || null;
    let month = data[`${identifier}_month`] || null;
    let day = data[`${identifier}_day`] || null;
    let result = {};
    result[`${identifier}_year`] = null;
    result[`${identifier}_month`] = null;
    result[`${identifier}_day`] = null;
    let tempResult = "";
    if ( year == null || year == "" ) {
      tempResult = null;
    } else {
      tempResult = year;
      if ( month != null && String(month).length > 0 ) {
        tempResult += '-' + this.pad(month, 2);
        if ( day != null && String(day).length > 0 ) {
          tempResult += '-' + this.pad(day, 2);
        }
      }
    }
    result[identifier] = tempResult;
    return result;
  }

  private getMapSearchParameters() {
    let map_agg = this.mapAggregations.join(',');
    return {
      lat: this.lat,
      lon: this.lon,
      zoom: this.zoom,
      map_date: this.mapDate,
      map_agg,
    }
  }

  private getCoordsSearchParameters() {
    if ( this.searchedAtCoordinates?.hasOwnProperty('coords') ) {
      return {
        coords: this.searchedAtCoordinates.coords,
        coordinates_show_all: this.searchedAtCoordinates.coordinates_show_all
      }
    }
    return {}
  }


  public setSearchParameters(values, triggerRefresh=true){
    var searchParameters = {
      ...values,
      ...this.getDateToString(values, 'res_fd'),
      ...this.getDateToString(values, 'res_fd_from'),
      ...this.getDateToString(values, 'res_fd_to'),
      ...this.getDateToString(values, 'birth_fd'),
      ...this.getDateToString(values, 'birth_fd_from'),
      ...this.getDateToString(values, 'birth_fd_to'),
      ...this.getDateToString(values, 'death_fd'),
      ...this.getDateToString(values, 'death_fd_from'),
      ...this.getDateToString(values, 'death_fd_to'),
      ...this.getDateToString(values, 'emigration_fd'),
      ...this.getDateToString(values, 'emigration_fd_from'),
      ...this.getDateToString(values, 'emigration_fd_to'),
      ...this.getDateToString(values, 'expulsion_fd'),
      ...this.getDateToString(values, 'expulsion_fd_from'),
      ...this.getDateToString(values, 'expulsion_fd_to'),
      ...this.getDateToString(values, 'imprisonment_fd'),
      ...this.getDateToString(values, 'imprisonment_fd_from'),
      ...this.getDateToString(values, 'imprisonment_fd_to'),
      ...this.getDateToString(values, 'deportation_fd'),
      ...this.getDateToString(values, 'deportation_fd_from'),
      ...this.getDateToString(values, 'deportation_fd_to'),
      ...this.getMapSearchParameters(),
      ...this.getBaseSearchParameters(),
      ...this.getCoordsSearchParameters()
    };
    this.searchParameters = searchParameters;
    this.triggerFetchData();
  }

  getBaseSearchParameters(){
    return {
      language: this.languageCode_
    };
  }

  public clearSearchParameters(){
    this.searchParameters = {};
    this.triggerFetchData();
    this.searchByQuery = {
      results: [],
      aggregations: [],
      count: 0,
      next: null
    };
  }

  public getUITextElements() {
    var url = MTLAppConfig.server.address + 'configuration/?format=json&language_code=' + this.languageCode_;
    return this.http.get(url, this.getHttpOptions());
  }

  public fetchUITextElements() {
    this.getUITextElements().subscribe(
      data => {
          if (this.uITextElements_ != data){
            this.uITextElements.next(data);
            this.uITextElements_ = data;
          }
      }
    );
  }

  public getsearchByQueryNextPage() {
    var url = this.searchByQueryNextPage_;
    return this.http.get(url, this.getHttpOptions());
  }

  public changeMapZoom(direction: number): void {
    this.programmaticallySetMapFocus.next({
      type: 'center',
      values: {
        zoom: this.zoom + direction * 1,
      }
    });
  } 

  getJoinedQueryString( params:any ): string {
    let queryStringParams = [];
    for ( let key in params ) {
      queryStringParams.push(`${key}=${params[key]}`);
    }
    return queryStringParams.join('&');
  }

  async init() {
    console.log("INIT called")
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show progress spinner or progress bar
      }

      if (event instanceof NavigationEnd) {
          // Hide progress spinner or progress bar
          this.currentRoute = event.url;
          var url = event.url.substring(1);

          if (this.currentRoute) {
            this.fragmentSubscription = this.route.fragment.subscribe(fragment => this.fragment = fragment);
            setTimeout(() => {
              this.fragmentSubscription.unsubscribe();
              document.querySelector("#"+this.fragment)?.scrollIntoView({ block: 'start' });
            }, 800)
          }

          if ( url.split('#').length > 0 ) {
            url = url.split('#')[0];
          };
          if ( url.split('?').length > 0 ) {
            url = url.split('?')[0];
          };

          if (url != '' && url != null) {
            if ( url.substring(0,5) == 'page/') {
              var slug = url.substring(5)
              this.setPage(slug);
              this.setCurrentDisplay('page');
            }
            else if ( url.substring(0,4) == 'bio/' ) {
              if ( this.currentDisplay == 'landing-page' ){
                this.setCurrentDisplay('person-fullscreen');
                // this.setRightOverlayFullscreen(true);
              }
              else if ( !this.displayBiography )
              {
                this.setCurrentDisplay('person');
              }
              let personReference = url.substring(4); // (+) converts string 'id' to a number
              if ( this.personReference != personReference ) {
                this.personReference = personReference;
                console.log("set person reference to", this.personReference)
                this.setPersonReference(this.personReference);
              } 
              // In a real app: dispatch action to load the details here.
            }
          }
          else
          {
             this.setPage("");
             this.personReference = '';
          }
      }
  });
    this.getMenuItems();

 
    this.searchUrlGetParameters.pipe(
      debounceTime(this.debounceTimeMS),
      distinctUntilChanged()).subscribe( (getParameters:any) => { 
        if ( !getParameters.hasOwnProperty('queryString') ) { return; }
        let fetchMapData = false;
        let fetchListData = false;
        let queryChanged = false;
        let getParametersString = getParameters.queryString;
        if ( getParametersString.length > 0 && getParameters.mapQueryString.length > 0 ) {
          getParametersString += '&';
        }
        getParametersString += getParameters.mapQueryString;
        // let pageSize = this.pageSize;
        if ( this.currentGetParameters?.queryString != getParameters?.queryString ) {
          queryChanged = true;
          fetchListData = true;
          fetchMapData = true;
        }
        if ( this.currentGetParameters?.mapQueryString != getParameters?.mapQueryString ) {
          queryChanged = true;
          fetchMapData = true;
          if ( getParameters?.restrictToMapBounds === true ) {
            fetchListData = true;
          }
        }
        if ( getParameters?.fetchMapData === false ) {
          fetchMapData = false;
        }
        // if ( fetchListData === false ) {
        //   pageSize = 0;
        // }

        if ( queryChanged ) {
          this.currentlyLoading_ = {
            map: fetchMapData,
            list: fetchListData,
            next: false
          };
          this.currentlyLoading.next(this.currentlyLoading_);
          this.fetchSearchDataFromServer(getParametersString, this.currentlyLoading_);
        }
        this.currentGetParameters = getParameters;
      });

    this.searchUrlCoordinatesGetParameters.pipe(
      debounceTime(this.debounceTimeMS),
      distinctUntilChanged()).subscribe( (getCoordinatesParameters) => { 
        if ( !getCoordinatesParameters.hasOwnProperty('queryString') ) { return; }
        if ( this.currentGetCoordinatesParametersString != getCoordinatesParameters.queryString ) {
          this.fetchSearchAtCoordinatesDataFromServer(getCoordinatesParameters.queryString, getCoordinatesParameters.searchedAtCoordinates );
          this.currentGetCoordinatesParametersString = getCoordinatesParameters.queryString;
        }
      });

    this.route.queryParams.subscribe((params:any) => {
      
      // console.log("Query params", params, this.route.url)
      params = { ...params };
      let mapParams = {};
      let mapBoundsChanged = false;
      let languageCode = "en";
      let searchAtCoordinatesChanged = false;

      if ( params.hasOwnProperty('lat') ) {
        let lat = Number(params.lat);
        if ( !isNaN(lat) ) {
          if( lat != this.lat ) { 
            this.lat = lat;
            mapBoundsChanged=true;
          }
        }
        delete params.lat;
      }

      if ( params.hasOwnProperty('lon') ) {
        let lon = Number(params.lon);
        if ( !isNaN(lon) ) {
          if( lon != this.lon ) { 
            this.lon = lon;
            mapBoundsChanged=true;
          }
        }
        delete params.lon;
      }

      if ( params.hasOwnProperty('zoom') ) {
        let zoom = Number(params.zoom);
        if ( !isNaN(zoom) ) {
          if( zoom != this.zoom ) { 
            this.zoom = zoom;
            mapBoundsChanged=true;
          }
        }
        delete params.zoom;
      }

      if ( params.hasOwnProperty('map_agg') ) { 
        let tempMapAggregations = params['map_agg'].split(',');
        let mapAggregations = [];
        for ( let aggregation of tempMapAggregations ) {
          if ( MAP_AGGREGATIONS.includes(aggregation) ){
            mapAggregations.push(aggregation);
          }
        }
        if ( mapAggregations.length < 1 ) {
          mapAggregations = ['residence'];
        }
        this.mapAggregations = mapAggregations;
        mapParams['map_agg'] = mapAggregations.join(',');
        delete params.map_agg;
      }


      if ( params.hasOwnProperty('adminMode') ) {
        this.adminMode = params.adminMode === 'true';
        delete params.adminMode;
      }

      let restrictToMapBounds = false;
      if ( params.hasOwnProperty('restrict_to_map_bounds') ) {
        restrictToMapBounds = params.restrict_to_map_bounds;
      }

      if ( params.hasOwnProperty('map_date') ) { 
        this.mapDate = params['map_date'];
        // delete params.map_date;
      }
      let coordinates_show_all = false;
      


      // if ( this.mapDate != null ) { 
      //   params["map_date"] = this.mapDate +'-01-01';
      // }

      if ( this.refreshAddressSearch ) {
        searchAtCoordinatesChanged = true;
      }


      if ( params.hasOwnProperty('coords') ) { 
        let coords = params.coords.split(':');
        this.searchedAtCoordinates = {
          event_type: coords[0],
          lat: coords[1],
          lon: coords[2],
          coords: params.coords,
          coordinates_show_all: params.coordinates_show_all === 'true',
          map_date: params["map_date"]
        }
        delete params.coords;
        delete params.coordinates_show_all;
        searchAtCoordinatesChanged = true;
        this.refreshAddressSearch = false;
      } else if ( Object.keys(this.searchedAtCoordinates).length != 0 ) {
        this.searchedAtCoordinates = {};
        searchAtCoordinatesChanged = true;
        this.refreshAddressSearch = false;
      } 



      if ( !params.hasOwnProperty('language') ) {
        languageCode = window.navigator.language.split('-')[0].toLowerCase();
        if ( !this.languageChoices.hasOwnProperty(languageCode) ) {
          languageCode = 'en';
        }
        this.changeLanguageCode(languageCode);
      } else {
        languageCode = params.language;
      }
      if ( this.languageCode_ != languageCode && this.languageChoices.hasOwnProperty(languageCode) ) {
        this.setLanguageCode(languageCode);
      }
      // params.language = this.languageCode_;
      
      if ( this.zoom < 8 && restrictToMapBounds == false ) {
        mapParams['tl_lat'] = 66;
        mapParams['tl_lon'] = -180;
        mapParams['br_lat'] = -66;
        mapParams['br_lon'] = 180;
      } else {
        mapParams['tl_lat'] = this.tl_lat;
        mapParams['tl_lon'] = this.tl_lon;
        mapParams['br_lat'] = this.br_lat;
        mapParams['br_lon'] = this.br_lon;
      }
      mapParams['zoom'] = this.zoom;
      // mapParams['lat'] = this.lat;
      // mapParams['lon'] = this.lon;
      if ( this.consentGivenGoogleMaps_ === false ) {
        mapParams['map_agg'] = false;
      }

      

      let queryString = this.getJoinedQueryString(params);
      let mapQueryString = this.getJoinedQueryString(mapParams);
      this.searchUrlQueryString = queryString;
      this.searchUrlGetParameters.next({queryString, restrictToMapBounds, mapQueryString, fetchMapData: this.consentGivenGoogleMaps_});
      // if ( !( this.initialized==false && mapBoundsChanged ) ) {
      // }
      if ( searchAtCoordinatesChanged ) {
        if ( Object.keys(this.searchedAtCoordinates).length == 0 ) {
          this.handleCoordinatesListResults([], null);
        } else {
          if ( queryString.length > 0 ) { queryString += '&'; }
          queryString += `coords_event_type=${this.searchedAtCoordinates.event_type}&coords_lat=${this.searchedAtCoordinates.lat}&coords_lon=${this.searchedAtCoordinates.lon}&coordinates_show_all=${this.searchedAtCoordinates.coordinates_show_all}`;
          this.searchUrlCoordinatesGetParameters.next({queryString, searchedAtCoordinates: this.searchedAtCoordinates});
        }
      }
      if ( mapBoundsChanged ) {
        this.programmaticallySetMapFocus.next({
          type: 'center',
          initial: this.initialized === false,
          values: {
            lat: this.lat,
            lon: this.lon,
            zoom: this.zoom,
          }
        });
      }
      if ( this.initialized == false ) {
        this.initialQueryParams.next(params);
        this.initialized = true;
      }
     });
 
       
     
    //  let languageCodeSet = false;
    //  if(localStorage.getItem('MTL_rememberConsent') == 'true'){
    //    this.setGoogleMapsConsentGiven();
    //    var languageCodeFromLocalStorage = localStorage.getItem('MTL_languageCode');
    //    if ( languageCodeFromLocalStorage != undefined && languageCodeFromLocalStorage != '' && this.languageCode_ != languageCodeFromLocalStorage) {
    //      this.languageCode_ = languageCodeFromLocalStorage;
    //      this.languageCode.next(this.languageCode_);
    //      languageCodeSet = true;
    //    }
    //  }

    let permanentConsentGivenGoogleMaps = localStorage.getItem('MTL_rememberConsent_google_maps') == 'true';
    if ( permanentConsentGivenGoogleMaps ) {
      this.setGoogleMapsConsentGiven( true );
    }
    let permanentConsentGivenBiography = localStorage.getItem('MTL_rememberConsent_biography') == 'true';
    if ( permanentConsentGivenBiography ) {
      this.setBiographyConsentGiven( true );
    }
 
     
 
     if( this.consentGivenGoogleMaps_ ) {
       this.searchParametersChanged.next(false);
      //  this.triggerFetchData();
     }
 
     // this.fetchUITextElements();
       // if (this.currentDisplay)
     this.fetchPersonData();
     this.setPage(this.slug);
  }
  

  setScreenSize() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.isMobile = this.screenWidth <= 500;
  }
    
    constructor(private http: HttpClient, public route: ActivatedRoute, public router: Router) {
      this.authenticate();
      this.setScreenSize();
    
    // this.paypalDonationButton.next(paypalDonationButtonChoices[this.languageCode_]);
    // this.uITextElements.subscribe(
    //   data => {
    //     this.namingQualifiers = data.namingQualifiers;
    //     this.basisOfPersecutionQualifiers = data.basisOfPersecution;
    //     this.dateFlags = data.dateFlags;
    //     this.biologicalGender = data.biologicalGender;
    //   }
    // )
    // LOCATIONS_URL = "/locations.json";
    
  }

  private authenticate(): void {
    let httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      ...this.auth
    }
    this.http.post(MTLAppConfig.server.address + 'token/', httpOptions).subscribe(
      data => {
        this.jwtTokenAuthConnectionAttempts = 0;
        this.updateToken(data);
        // .then(()=>{
        //   this.init();
        // });
    },
    error => {
      this.jwtTokenAuthConnectionAttempts++;
      console.error(error);
      if ( this.jwtTokenAuthConnectionAttempts < 3 ) {
        setTimeout(() => this.authenticate(), 1000);
      } else {
        setTimeout(() => window.location.reload(), 10000);
      }
    });
  }

  private getHttpOptions():any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.jwtTokenAccess
      }),
    }
  }

  private async updateToken(data:any):Promise<void> {
    this.jwtTokenAccess = data["access"];
    this.jwtTokenRefresh = data["refresh"];
    localStorage.setItem('jwtTokenAccess', this.jwtTokenAccess);
    localStorage.setItem('jwtTokenRefresh', this.jwtTokenRefresh);
    const token_parts = this.jwtTokenAccess.split(/\./);
    const token_decoded = JSON.parse(window.atob(token_parts[1]));
    this.tokenExpiresDate = new Date(token_decoded.exp * 1000);
    let updateTokenIn = this.tokenExpiresDate - Date.now() - 500;
    this.refreshTokenTimeout = setTimeout(()=>{this.refreshToken()}, updateTokenIn);
    this.tokenExpires = token_decoded.exp;
    // this.userId = String(token_decoded.user_id);
    // localStorage.setItem('userId', this.userId);
    localStorage.setItem('tokenExpires', this.tokenExpires);
    if ( this.isAuthenticated == false ) {
      this.init();
    }
    this.isAuthenticated = true;
    this.isAuthenticatedSub.next(true);
  }

  private refreshToken(): void{
    let httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    }
    this.http.post(MTLAppConfig.server.address + 'token/refresh/', JSON.stringify({refresh: this.jwtTokenRefresh}), httpOptions).subscribe(
      data => {
        this.updateToken(data);
      },
      error => {
        this.isAuthenticated = false;
        this.router.navigate([], { relativeTo:this.route, queryParamsHandling: 'preserve'});
      }
    );
  }




  public setCooperationPartner(cooperationpartner): void {
    var url = MTLAppConfig.server.address + 'cooperationpartner/' + cooperationpartner + '/?format=json';
    this.http.get(url, this.getHttpOptions()).subscribe(
      data => {
        this.cooperationPartnerData_ = data;
        // console.log('coop: ' + data)
        // console.log(data)
        this.cooperationPartnerData.next(this.cooperationPartnerData_);
        this.embeddedView.next(this.cooperationPartnerData_['embedded_view']);
    },
    error => console.error(error),
    () => {
      }
    );
    
    // .subscribe(
    //     data => {
    //       try {
            // console.log(data)
    //           // this.cooperationpartner_data.name = data['name'];
    //           this.cooperationpartner_data.center = data['center'];
    //           this.cooperationpartner_data.zoom = data['zoom'];
    //       }
    //       catch(e) {
    //         console.error(e);
    //       }
    //     },
    //     error => console.error(error),
    //     () => {
    //         this.cooperationpartner_reference = cooperationpartner;
    //       }
    //   );
  }

  public setMapBounds(zoom=6, tl_lat = 66, tl_lon=-180, br_lat=-66, br_lon=180, lat=50.3, lon=12.3, triggerUpdate=true): void {
    this.tl_lat = tl_lat;
    this.tl_lon = tl_lon;
    this.br_lat = br_lat;
    this.br_lon = br_lon;
    this.zoom = zoom;
    this.lat = lat;
    this.lon = lon;
    let triggerUpdateParams = {};
    if ( triggerUpdate ) {
      triggerUpdateParams['map_loaded'] = new Date().getTime();
    }
    // if ( this.mapAggregations.length > 0 ) { searchParameters["map_agg"] =  this.mapAggregations; }
    this.router.navigate([], { relativeTo: this.route, queryParams: { lat, lon, zoom, ...triggerUpdateParams }, queryParamsHandling: 'merge' });
    // this.triggerFetchData();
    // if ( this.zoom == zoomlevel && zoomlevel < 7 ) {
    //   return;
    // }
    // this.zoom = zoomlevel;
    // if ( triggerUpdate ) {
    //   this.triggerFetchData();
    // }
  }

  handleListResults(data, currentlyLoading:any, url:string): void {
    // console.log('handleListResults', data, currentlyLoading);
    // if ( refreshGeoData ) {
      // }
    if ( data.hasOwnProperty('facets') == true && currentlyLoading.map ) {
      this.geoPositionData.next(data['facets']);
      this.searchByQuery.aggregations = data['facets'];
    } else {
      this.searchByQuery.aggregations = [];
    }
    if ( data.hasOwnProperty('count') == true ) {
      this.searchByQuery.count = data['count'];
    } else {
      this.searchByQuery.count = 0;
    }
    if ( url !== this.currentlyDisplayedListUrl ) {
      if ( currentlyLoading.list ) {
        if ( data.hasOwnProperty('results') == true ) {
          this.searchByQuery.results = [...data['results']];
          this.currentSearchDisplay = 'results';
        } else {
          this.searchByQuery.results = [];
        }
        if ( data.hasOwnProperty('next') == true ) {
          this.searchByQuery.next = data['next'];
        } else {
          this.searchByQuery.next = null;
        }
      }
    }

    // console.log("searchByQuery", this.searchByQuery);
    // this.mapDataLoading = false;
  
  }
  handleCoordinatesListResults(data, searchedAtCoordinates): void {
    this.currentlyDisplayedListUrl = '';
    if ( searchedAtCoordinates === null ) {
      this.searchByAddress = {
        results: [],
        count: 0,
        next: null,
        searchedAtCoordinates: null,
      };
    } else {
      this.searchByAddress = {
        results: [...data.results],
        count: data.count,
        next: data.next,
        searchedAtCoordinates,
      };
    }
  }

  public setSearchInProgress(value): void {
    this.searchInProgress.next(value);
  }

  public searchAtCoordinates(center, eventType='residence') {
    this.setCurrentDisplay("results");
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        coords: `${eventType}:${center.lat}:${center.lng}`,
      },
      queryParamsHandling: 'merge'
    });
    // console.log('searching at', center.toJSON())
    // this.setSearchInProgress(true);

    // // if (filter == false) {
    //   // }
    // this.searchAtCoordinatesCoordinates = center.toJSON();
    // var url = MTLAppConfig.server.address + 'search/search_by_place/?format=json&page_size=' + this.pageSize + '&res_lat=' + this.searchAtCoordinatesCoordinates.lat + '&res_lon=' + this.searchAtCoordinatesCoordinates.lng + '&event_type=' + eventType;
    // url += this.combineSearchParameters();
    // this.http.get(url, this.getHttpOptions()).subscribe(
    //   data => {
    //     this.searchByAddress_ = data['results'];
    //     this.searchByAddress.next(data['results']);
    //     if ( data.hasOwnProperty('count') == true ) {
    //       this.searchByAddressResultCount.next(data['count']);
    //     }
    //     else
    //     {
    //       this.searchByAddressResultCount.next(0);
    //     }
    //   });
    // this.setSearchInProgress(false);
  }

  // public getGeoPositionData(): Observable<any> {
  //   return this.geoPositionData.asObservable();
  // }

  // public getSearchByQuery(): Observable<any> {
  //   return this.searchByQuery.asObservable();
  // }

  public setPersonReference(reference): void {
    this.personReference = reference;
    this.fetchPersonData();
  }

  fetchPersonData(): void {
    console.log("Fetching person data")
    if (this.personReference != "" && this.jwtTokenAccess.length > 0 ) {
      this.displayBiography = true;
      this.personDataLoadingStatus.next(0);
      this.personData_ = null;
      var url = MTLAppConfig.server.address + 'search/' + this.personReference + '/detailed_biography/?language=' + this.languageCode_;
      this.http.get(url, this.getHttpOptions()).subscribe(
        (data:any) => {
          // console.log(data);
          // if ( data.hasOwnProperty("redirectTo") ) {
          //   this.router.navigate(['/bio', data.redirectTo], {queryParamsHandling: 'preserve'});
          // } else {
            this.personData_ = data;
            this.personData.next(data);
 
            let lastViewedLength = this.lastViewedBiographies_.length;
            for(let i=0; i < lastViewedLength; i++) {
              if( this.lastViewedBiographies_[i]?.reference == this.personReference ){
                this.lastViewedBiographies_.splice(i,1);
              }
              else if( i > 4) {
               this.lastViewedBiographies_.splice(i,1);
              }
            }
            this.lastViewedBiographies_.unshift(data.listDisplayInformation);
            this.lastViewedBiographies.next(this.lastViewedBiographies_);
            this.personDataLoadingStatus.next(1);
          // }
        },
        error => {
          if ( error.status == 404 ) {
            this.personDataLoadingStatus.next(2);
          } else {
            this.personDataLoadingStatus.next(3);
          }
        }
        );
    }
    this.personData.next({});
    
  }

  triggerFetchData(): void {
    
    // clearTimeout(this.updateTimer);
    // this.updateTimer = setTimeout(() => { this.fetchSearchDataFromServer() }, this.updateInterval);

    // let getParameterString = 'search/?format=json&page_size=' + this.pageSize + '&zoom=' + this.zoom + '&tl_lat=' + this.tl_lat + '&tl_lon=' + this.tl_lon + '&br_lat=' + this.br_lat + '&br_lon=' + this.br_lon;
    // let combinedSearchParameters = this.combineSearchParameters();
    // getParameterString += combinedSearchParameters;

    let searchParameters = {

      ...this.removeEmpty(this.searchParameters)
    };
    // console.log('api.service triggerFetchData', searchParameters, this.route);
    this.router.navigate([], { relativeTo: this.route, queryParams: searchParameters });
  }

  removeEmpty(obj:any):any {
    return Object.keys(obj).reduce((acc, key) => ((obj[key] === undefined || obj[key] === null || obj[key] === '') ? acc : {...acc, [key]: obj[key]}), {});
  }

  combineSearchParameters(): string {
    var result = "";
    for (let key in this.searchParameters) {
        let value = this.searchParameters[key];
        if ( key == 'restrict_to_map_bounds' ) {
          result += "&" + key + "=" + value;
        }
        else
        {
          if ( value != "" && value != null ) {
            result += "&" + key + "=" + value;
          }
        }

    }
    return result;
  }

  searchByQueryFetchNextPage(): void {
    if (this.searchByQuery.next != null) {
      this.currentlyLoading_.next = true;
      this.http.get(this.searchByQuery.next, this.getHttpOptions()).subscribe(
        data => {
          if ( data.hasOwnProperty('results') == true ) {
            this.searchByQuery.results = this.searchByQuery.results.concat(data['results']);
          }
          if ( data.hasOwnProperty('next') == true ) {
            this.searchByQuery.next = data['next'];
          } else {
            this.searchByQuery.next = null;
          }
          this.currentlyLoading_.next = false;
        }
      )
    }
  }

  searchByAddressFetchNextPage(): void {
    if (this.searchByAddress.next != null) {
      this.currentlyLoading_.next = true;
      this.http.get(this.searchByAddress.next, this.getHttpOptions()).subscribe(
        data => {
          if ( data.hasOwnProperty('results') == true ) {
            this.searchByAddress.results = this.searchByAddress.results.concat(data['results']);
          }
          if ( data.hasOwnProperty('next') == true ) {
            this.searchByAddress.next = data['next'];
          } else {
            this.searchByAddress.next = null;
          }
          this.currentlyLoading_.next = false;
        }
      )
    }
  }


  private fetchSearchAtCoordinatesDataFromServer(urlGetParametersString:string, searchedAtCoordinates): void {
    if ( urlGetParametersString == undefined ) { return; }
      // this.setSearchInProgress(true);
      var url = MTLAppConfig.server.address + `search/search_by_place/?format=json&page_size=${this.pageSize}&` + urlGetParametersString;

      this.currentlyLoading_.list = true;
      this.http.get(url, this.getHttpOptions()).subscribe(
        (data) => {
          this.setCurrentDisplay("search");
          this.currentSearchDisplay = 'results';
          this.handleCoordinatesListResults(data, searchedAtCoordinates);
          this.currentlyLoading_.list = false;
        }
        );
      // return response;
  }

  private fetchSearchDataFromServer(urlGetParametersString:string, currentlyLoading:any): void {
    if ( urlGetParametersString == undefined ) { return; }
    // this.setSearchInProgress(true);
    var url = MTLAppConfig.server.address + `search/?format=json&page_size=${this.pageSize}&` + urlGetParametersString + `&map_only=${currentlyLoading.list === false ? 'true' : 'false'}`;
    this.http.get(url, this.getHttpOptions()).subscribe(
      (data) => {
        this.handleListResults(data, currentlyLoading, url);
        this.currentlyLoading_.map = false;
        this.currentlyLoading_.list = false;
        }
      );
      // return response;
  }
  //
  // public getMapData(zoomlevel=6, tl_lat = 66, tl_lon=-180, br_lat=-66, br_lon=180): Observable<any> {
      // console.log("Calling this");
  //
  // }
}
