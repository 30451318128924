import { Component, Input, OnInit, ViewChild, TemplateRef, AfterViewInit, HostListener } from '@angular/core';
import { ApiService } from '../api.service';
import { ApplicationStateService } from '../application-state.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Gallery, GalleryImageDef, GalleryItemDef } from 'ng-gallery'
import { Lightbox } from 'ng-gallery/lightbox'

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.less'],
  host: { class: 'app-person' }
})
export class PersonComponent implements OnInit {

  @ViewChild(GalleryImageDef, { static: true }) imageDef!: GalleryImageDef;

  dataLoadingStatus = 0;
  person: any;
  events: any;
  minimizeName: boolean = false;

  onScrollListener:any;

  // person: any;
  private sub: any;
  public uiTranslations: any;
  public languageCode = 'en';
  embeddedView = false;
  public linkUrl = '';
  public copiedSucessfully = false;
  currentDisplay = 'landing-page';
  personDetails = {
      personBirth: null,
    };

  joinedEvents: any[];

  coopPartnerDocumentsDetails:any = {}

  public rememberConsentBiography: boolean = false;
  public rememberConsentBiographyPermanent: boolean = false;

  getRedirectUrl(): string {
    return `https://${window.location.host}/bio/${this.person.redirectTo.reference}`;
  }

  setDocumentDetails(document: any, link:any, cooperationPartnerId: any): string {
    if ( !this.coopPartnerDocumentsDetails.hasOwnProperty(document.img) ) {
      this.coopPartnerDocumentsDetails[document.img] = {
        ...document, 
        cooperationPartnerId
      };
      if ( !this.coopPartnerDocumentsDetails[document.img].hasOwnProperty("link") ) {
        this.coopPartnerDocumentsDetails[document.img]['link'] = link;
      }
    }
    return document.img;
  }

  getDocumentDetails(item:any) :any {
    let key = item.src;
    if ( !this.coopPartnerDocumentsDetails.hasOwnProperty(key) ) {
      key = key.replaceAll('%20', ' ');
    }
    return this.coopPartnerDocumentsDetails[key] || undefined;
  }

  addittionalInformationShown:any = {};

  getObjectKeyLength(obj:any):number {
    if ( typeof(obj) == "object" ) {
      return Object.keys(obj).length;
    }
    return 0;
  }

  getObjectKeys(obj:any, keys:string[]):any {
    let result = [];
    for ( let key of keys ) {
      if ( obj.hasOwnProperty(key) ) { result.push(key); } 
    }
    return result;
  }


  getJoinedEvents(person:any):any[] {
    let result = [];
    if ( person?.eventsPersonalDetails?.hasOwnProperty('BIRTH') ) { result.push(person.eventsPersonalDetails.BIRTH); }
    if ( person?.eventsPersonalDetails?.hasOwnProperty('DEATH') ) { result.push(person.eventsPersonalDetails.DEATH); }
    if ( person?.hasOwnProperty("events") ) {
      result = [...result, ...person.events];
    }
    if ( person?.eventsDateUnknown != null ) {
      for (let [key, value] of Object.entries(person.eventsDateUnknown)) {
        value = value as any[];
        result = result.concat(value);
      }
    }
    return result;
  }

  toggleAdditionalInformation(name:string):void {
    this.addittionalInformationShown[name] = !(this.addittionalInformationShown[name] || false);
  }


  constructor(public apiService: ApiService, public applicationState: ApplicationStateService, private clipboard: Clipboard, public gallery: Gallery, public lightbox: Lightbox) {
    this.apiService.personDataLoadingStatus.subscribe(
      value => {
        this.dataLoadingStatus = value;
      }
    )
    this.applicationState.currentDisplay.subscribe(
      value => {
        this.currentDisplay = value;
      }
  );
    this.apiService.uITextElements.subscribe(
      data => {
        this.uiTranslations = data.person;
      }
    );
    this.apiService.languageCode.subscribe(
      data => {
        this.languageCode = data;
      }
    );
    this.apiService.embeddedView.subscribe(
      data => {
        this.embeddedView = data;
      }
    );
    this.apiService.personData.subscribe(
      data => {
         this.person = data;
      },
    );
  }

  galleryReferences = [];

  public getGalleryReference(cooperationPartner: string, reference: string) :string {
    let val = `${cooperationPartner}-${reference}`;
    if ( this.galleryReferences.indexOf(val) === -1 ) {
      this.galleryReferences.push(val);
      this.gallery.ref(val).setConfig({
        imageTemplate: this.imageDef.templateRef
      });
      // this.gallery.ref(val, {
      //   itemTemplate: this.galleryItemDef
      // });
    }
    return val;
  }

  public showLink(): void {
    this.linkUrl = 'https://mappingthelives.org/bio/' + this.person?.reference + '?language=' + this.languageCode;
  }

  public copyLink(): void {
    try {
      this.clipboard.copy(this.linkUrl);
      // window.alert(this.uiTranslations?.copiedSucessfully);
      this.copiedSucessfully = true;
      setTimeout(() => {
          this.copiedSucessfully = false;
        },2000)
    }
    catch(e) {
      this.copiedSucessfully = false;
    }
  }


  public replaceNamingQualifierPlaceholder(name: string): string {
    return this.apiService.replaceNamingQualifierPlaceholder(name);
  }

  public replaceBasisOfPersecutionPlaceholder(name: string): string {
    return this.apiService.replaceBasisOfPersecutionPlaceholder(name);
  }

  public replaceDateFlagPlaceholder(name: string): string {
    return this.apiService.replaceDateFlagPlaceholder(name);
  }

  public replaceGenderPlaceholder(name: string): string {
    return this.apiService.replaceGenderPlaceholder(name);
  }

  public scrollToSource(source) {
    source = source.replace('.', '\\.');
    document.querySelector('#source-'+source)?.scrollIntoView({ block: 'start' });
  }

  ngOnInit(): void {
    // const lightboxRef = this.gallery.ref('lightbox');
    // console.log(this.galleryReferences)
    // for ( const reference of this.galleryReferences ) {
    //   console.log(reference)
    //   this.gallery.ref(reference, {
    //     itemTemplate: this.imageDef.templateRef
    //   });
    // }
    
  }
  scrollHandler(event:any) {
    if ( event.target.scrollTop > 50 ) {
      this.minimizeName = true;
    } else {
      this.minimizeName = false;
    }
  }

  ngAfterViewInit(): void {
    
    // for ( let val of this.galleryReferences ) {
    //   this.gallery.ref(val).setConfig({
    //     itemTemplate: this.galleryItemDef
    //   });
    //   console.log(this.gallery)
    // }
    // Add custom gallery config to the lightbox (optional)
  //   this.applicationState.setBlurMap(true);
    
  //
   }

}


