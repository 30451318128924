<div class="menu" [ngClass]="{'disappear': !menuOpen}">
    <div class="menu-header-wrapper">
      <div class="menu-bar-fixed-icons">
        <div>
          <img src="/assets/menu.svg" class="menu-bar-icon button-icon menu-icon inverted" [ngClass]="{'mobile-view': applicationState.mobileView}" (click)="applicationState.setMenuOpen(false);">
          <!-- <span>{{ 'labels.closeMenu' | translate }}</span> -->
        </div>
        <div>
          <img src="/assets/search_dark.svg" class="menu-bar-icon button-icon search-app-search-icon">
          <span>{{ 'labels.search' | translate }}</span>
        </div>
      </div>
      <img src="/assets/mtl.svg" class="menu-bar-mtl-logo">
    </div>
    <ul class="menu-content-wrapper">
      <li class="menu-item menu-item-align-top" [ngClass]="{'menu-item-selected':(currentPage=='' && currentDisplay=='landing_page')}" (click)="applicationState.setCurrentDisplay('landing_page'); applicationState.setMenuOpen(false)" [routerLink]="[ '/' ]">
        {{ uiTranslations?.landingPage.label }}
      </li>
      <li *ngFor="let menuItem of menuItems" class="menu-item menu-item-align-top" (click)="applicationState.setCurrentDisplay('page'); applicationState.toggleMenuCloseOnMobile();" [ngClass]="{'menu-item-selected':(currentPage==menuItem.slug && currentDisplay=='page')}" [routerLink]="[ '/page/', menuItem.slug ]">
        {{ menuItem.label }}
      </li>
      <li class="menu-item-spacer"></li>
      <li class="menu-item-last-viewed" *ngIf="lastViewedBiographies.length > 0">
        <span class="menu-last-viewed-title"> {{ uiTranslations?.lastViewedBiographies }} </span>
          <ul class="menu-last-viewed-wrapper">
          <li *ngFor="let lastViewedBiography of lastViewedBiographies.sort( compareFullnames )"  class="menu-item menu-item-align-top last-viewed-item" (click)="applicationState.toggleMenuCloseOnMobile(); applicationState.setCurrentDisplay('person_or_person_fullscreen');" [ngClass]="{'menu-item-selected':(apiService.personReference==lastViewedBiography.reference && ['person', 'person_fullscreen'].indexOf(currentDisplay) > -1 ) }" [routerLink]="['/bio/', lastViewedBiography?.reference ]">
              <span class="last-viewed-biographies-name">{{ getValueToDisplay(lastViewedBiography?.fullname) }}</span>
              <span class="last-viewed-biographies-address">{{ getValueToDisplay(lastViewedBiography?.residence_on_map?.place_name_display_list) }}</span>
          </li>
        </ul>
      </li>
    </ul>

    
    <div class="language-switcher">
      <img src="/assets/translations.svg" class="translations-icon inverted">
      <div class="language-choice-wrapper">
        <span *ngFor="let language of languageChoices" class="menu-item language-choices-item" (click)="apiService.setLanguageCode(language.value)" [ngClass]="{ 'menu-item-selected': language.value == languageCode }">{{ language.display }}</span>
      </div>
    </div>
  </div>