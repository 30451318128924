import { Component } from '@angular/core';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrl: './lightbox.component.less',
  host: { class: 'app-lightbox' }
})
export class LightboxComponent {

}
