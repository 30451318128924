import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HighlightComponent} from './highlight.component';
import {HighlightDirective} from './highlight.directive';

@NgModule({
  declarations: [
    HighlightComponent,
    HighlightDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [HighlightDirective]
})
export class HighlightModule {
}