<div class="page-wrapper">
  <!-- <div class="page-header">
    <div class="open-menu-wrapper" (click)="menuOpen=!menuOpen">
      <img src="/assets/menu.svg" class="menu-icon" [ngClass]="{'inverted': menuOpen}">
    </div>
    <div class="close-wrapper" (click)="applicationState.setCurrentDisplay('search')" [ngClass]="{'inverted': menuOpen}">
      <img class="close-icon" src="/assets/close.svg">
      <span class="close-page-text">{{ uiTranslations?.browseMap }}</span>
    </div>
  </div> -->
    <h2>{{ page?.title }}</h2>
    
    <div class="page-content flatpage">
      <div class="loading-spinner-wrapper" *ngIf="dataLoadingStatus==0"><div class="loading-spinner"><div></div><div></div><div></div><div></div></div></div>
      <div class="page-content-flatpage" *ngIf="dataLoadingStatus==1" [innerHTML]="page?.content">
      </div>
      <ng-element *ngIf="currentPage=='support-us'">
        <div class="donate-button-container">
          <a href="{{ paypalDonationButton?.url }}" target="_blank" class="button paypal"><img class="paypal-donation-button" src="{{ paypalDonationButton?.imgSrc }}" alt="{{ paypalDonationButton?.imgAlt }}"></a>
        </div> 
      </ng-element>
      <ng-element *ngIf="currentPage=='imprint'">
        
        <h3 id="technical-realisation">{{ uiTranslations?.imprintPage?.technicalRealisationBy }}</h3>
        <div class="page-technical-realisation-wrapper">
          <a target="_blank" href="{{ uiTranslations?.imprintPage?.luxURL }}" class="page-technical-realisation-box">
            {{ uiTranslations?.imprintPage?.luxCredit }}
            <img class="page-technical-realisation-logo" src="/assets/LUX.svg">
          </a>
        </div>
        <hr>
        <h3 class="page-privacy-settings-header" id="privacy-settings">
          {{ uiTranslations?.imprintPage?.privacySettings }}
        </h3>
        <div class="page-privacy-settings-wrapper">
          <p class="consent-form-text">{{ uiTranslations?.imprintPage?.googleMapsGDPRConsentText }}</p>
          <p class="consent-form-further-information">{{ uiTranslations?.imprintPage?.googleMapsGDPRFurtherInformationText }}</p>
          <a target="_blank" href="{{ uiTranslations?.imprintPage?.googleMapsGDPRFurtherInformationLink }}">{{ uiTranslations?.imprintPage?.googleMapsGDPRFurtherInformationLink }}</a>
          <p>{{ uiTranslations?.imprintPage?.usedCookies }}</p>
          
          <div class="privacy-settings-container">
            <mat-slide-toggle class="remember-consent-toggle"
            [labelPosition]="'before'"
            [checked]="rememberConsent"
            [color]="'#00A9B0'"
            (change)="apiService.togglePermanentConsent()"
            [disabled]="!rememberConsent">
            {{ uiTranslations?.imprintPage?.rememberConsent }}
            </mat-slide-toggle>
          </div>
        </div>
      </ng-element>
    </div>
  </div>
