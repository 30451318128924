import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'highlight',
  templateUrl: './highlight.component.html',
  styleUrl: './highlight.component.less'
})
export class HighlightComponent implements OnInit {
  ngOnInit(): void {
    
  }

}
