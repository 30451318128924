<ng-container *ngIf="isObject(person)">
  <tr class="person-list-item" [routerLink]="['/bio/', person.reference ]" [queryParamsHandling]="'preserve'" (click)="apiService.setCurrentDisplay('person')" [ngClass]="{active: person.reference == apiService.personReference}">
      <td class="person-list-name">{{ getValueToDisplay(person?.fullname_display_list) }}</td>
      <div class="person-dates-wrapper">
        <td class="hidden-in-tabular person-list-birth-sign">{{ (person?.birth?.hasOwnProperty('fuzzy_date_mean') && person.birth.fuzzy_date_mean != '') ? '*' : '' }}</td>
        <td class="person-list-birth-date">{{ getValueToDisplay(person?.birth?.fuzzy_date_display) }}</td>
        <td class="hidden-in-list person-list-birth-place">{{ getValueToDisplay(person?.birth?.short_stringified_name) }}</td>
        <td class="hidden-in-tabular person-list-death-sign">{{ (person?.death?.hasOwnProperty('fuzzy_date_mean') && person.death.fuzzy_date_mean != '') ? '†' : '' }}</td>
        <td class="person-list-death-date">{{ getValueToDisplay(person?.death?.fuzzy_date_display) }}</td>
        <td class="hidden-in-list person-list-death-place">{{ getValueToDisplay(person?.death?.short_stringified_name ) }}</td>
      </div>
      <td class="person-list-address residence">
        <div class="place-wrapper" *ngFor="let place of getPlaces(person, searchedAtCoordinates, mapDate).residence">
          <div class="map-marker place residence" [ngClass]="{default: place.default, searchedAt: place.searchedAt}"></div>
          <div class="place-label">{{ place.label }}</div>
        </div>
      </td>
      <td class="person-list-address emigration" *ngIf="searchedAtCoordinates?.event_type === 'emigration'">
        <div class="place-wrapper" *ngFor="let place of getPlaces(person, searchedAtCoordinates, mapDate).emigration">
          <div class="map-marker place emigration" [ngClass]="{default: place.default, searchedAt: place.searchedAt}"></div>
          <div class="place-label">{{ place.label }}</div>
        </div>
      </td>
      <td class="person-list-address deportation" *ngIf="searchedAtCoordinates?.event_type === 'deportation'">
        <div class="place-wrapper" *ngFor="let place of getPlaces(person, searchedAtCoordinates, mapDate).deportation">
          <div class="map-marker place deportation" [ngClass]="{default: place.default, searchedAt: place.searchedAt}"></div>
          <div class="place-label">{{ place.label }}</div>
        </div>
      </td>


    </tr>
  
  
</ng-container>